import FetchAbstraction from "@/utils/FetchAbstraction";
import { closeNotification, makeNotification } from "../utils/notificationHandler";
import { DELETE_ADDRESS_TEMPLATE_KEY, EDIT_ADDRESS_TEMPLATE_KEY, DELETE_SUPPLIER_TEMPLATE_KEY, EDIT_SUPPLIER_TEMPLATE_KEY, EDIT_PERSONAL_REF, EDIT_PERSONAL_REF_ERROR } from "../components/warehouse/notificationConstants";

const defaultProductObj = {
    key: 0,
    description: {
      name: '',
      dictionary: 'Omschrijving van het product in het Nederlands',
      isFocused: false
    },
    hsCode: {
      name: '',
      dictionary: 'HS-Code',
      isFocused: false
    },
    enaCode: {
      name: '',
      dictionary: 'EAN-Code',
      isFocused: false
    },
    pricePerPiece: {
      name: null,
      dictionary: 'Prijs per stuk in USD',
      isFocused: false
    },
    otherCosts: {
      name: null,
      dictionary: 'Overige kosten in USD',
      isFocused: false
    },
    totalValue: {
      name: null,
      dictionary: 'Totale waarde in USD',
      isFocused: false
    },
    quantityInBooking: {
      name: null,
      dictionary: 'Hoeveelheid producten',
      isFocused: false
    },
    quantityBoxes: {
      name: null,
      dictionary: 'Hoeveelheid dozen',
      isFocused: false
    },
    grossWeight: {
      name: null,
      dictionary: 'Bruto gewicht/Gross weight',
      isFocused: false
    },
    cbm: {
      name: null,
      dictionary: 'CBM',
      isFocused: false
    },
    leaveInWarehouse: {
      name: null,
      dictionary: 'Wil jij een deel opslaan in het warenhuis?',
      isFocused: false,
    },
    countBoxesToLeaveInWarehouse: {
      name: null,
      dictionary: 'Hoeveel dozen wil je laten opslaan?',
      isFocused: false,
    },
    isDangerous: {
      name: null,
      dictionary: 'Gevaarlijke goederen?',
      isFocused: false,
    },
    subProductObj: {
      counter: 0,
      product_subs: []
    },
}

const defaultSubProductForm = () => ({
  quantityBoxes: {
    name: null,
    dictionary: 'Hoeveelheid dozen',
    isFocused: false
  },
  description: {
    name: null,
    dictionary: 'Product specificaties',
    isFocused: false
  },
  leaveInWarehouse: {
    name: null,
    dictionary: 'Wil jij een deel opslaan in het warenhuis?',
    isFocused: false,
  },
  quantityInBooking: {
    name: null,
    dictionary: 'Hoeveel dozen wil je laten opslaan?',
    isFocused: false
  },
})

const defaultMkTwoForm = () => ({
  counter: 0,
  isDeleteBtnDisabled: true,
  products: [
    JSON.parse(JSON.stringify(defaultProductObj)),
  ],
  document: {
    file: [],
    errorText: [],
    isFileLoading: false,
    isDirty: false,
    isFileRemoving: false
  },
  error: ''
})

const autoFillThirdStepData = {
  lvb: 0,
  street: 'Warenhuis',
  houseNumber: 'Warenhuis',
  addition: 'Warenhuis',
  countryIndex: 1,
  dhlInsured: 0,
  city: 'Warenhuis',
  postcode: 'Warenhuis',
};
const INITIAL_PAGINATION = () => ({
  totalItems: null,
  itemsPerPage: null,
  currentPage: null,
});
const INITIAL_SEARCH_FILTER = () => ({
  searchString: '',
  bookingStatus: '',
});
const SUPPLIER_TEMPLATE_DATA = {
  city: "recipientAddress",
  email: "providerEmail",
  name: "providerName",
  phone: "providerPhone",
  postcode: "providerPostCode",
  street: "providerStreetHouseName",
};
const DELIVERY_TEMPLATE_DATA = {
  street: "street",
  house_number: "houseNumber",
  addition: "addition",
  country_index: "countryIndex",
  city: "city",
  post_code: "postcode",
};
export default {
  state: {
    currentStage: 1,
    stageCount: 4,
    formTitle: 'Gegevens van de leverancier',
    formSubtitle: 'Voer hier de gegevens in van de leverancier van je goederen',
    isLoading: false,
    errorText: '',
    isChangingTemplateData: false,
    isLoadingTemplate: false,
    templatesError: [],
    personalRefError: [],
    mkOne: {
      providerName: {
        name: '',
        isFocused: false,
        loading: false,
        dictionary: 'Bedrijfsnaam leverancier',
        dataSource: []
      },
      providerEmail: {
        name: '',
        isFocused: false,
        dictionary: 'E-mailadres leverancier',
      },
      providerPhone: {
        name: '',
        isFocused: false,
        dictionary: 'Telefoonnummer leverancier',
      },
      providerStreetHouseName: {
        name: '',
        isFocused: false,
        dictionary: 'Straatnaam + huisnummer leverancier',
      },
      providerPostCode: {
        name: '',
        isFocused: false,
        dictionary: 'Postcode leverancier',
      },
      recipientAddress: {
        name: '',
        isFocused: false,
        dictionary: 'Plaatsnaam leverancier',
      },
      transportType: {
        name: null,
        dictionary: 'Transport type',
        isFocused: false,
        data: []
      },
      incotermsRule: {
        name: null,
        dictionary: 'Afgesproken Incoterm',
        isFocused: false,
      },
      warhouseId: {
        name: '',
        isFocused: false,
        dictionary: 'Op welke locatie levert jouw leverancier de goederen aan',
      },
      customWarehouseAddress: {
        name: '',
        isFocused: false,
        dictionary: 'Stad, Land',
      },
      isDangerousMkOne: {
        name: null,
        isFocused: false,
        dictionary: 'Zal de boeking gevaarlijke producten bevatten?',
      },
      saveProvider: false,
      errorMessage: '',
      templateData: {
        providerName: {
          id: 1,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Bedrijfsnaam leverancier',
          inputSettings: {
            type: 'Input'
          }
        },
        providerEmail: {
          id: 2,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'E-mailadres leverancier',
          inputSettings: {
            type: 'Input'
          }
        },
        providerPhone: {
          id: 3,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Telefoonnummer leverancier',
          inputSettings: {
            type: 'Input'
          }
        },
        providerStreetHouseName: {
          id: 4,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Straatnaam + huisnummer leverancier',
          inputSettings: {
            type: 'Input'
          }
        },
        providerPostCode: {
          id: 5,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Postcode leverancier',
          inputSettings: {
            type: 'Input'
          }
        },
        recipientAddress: {
          id: 6,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Plaatsnaam leverancier',
          inputSettings: {
            type: 'Input'
          }
        },
      }
    },
    mkTwo: {
      counter: 0,
      isDeleteBtnDisabled: true,
      products: [
        JSON.parse(JSON.stringify(defaultProductObj)),
      ],
      document: {
        file: [],
        errorText: [],
        isFileLoading: false,
        isDirty: false,
        isFileRemoving: false
      },
      error: ''
    },
    mkThree: {
      saveDeliveryData: false,
      error: '',
      lvb: {
        id: 1,
        name: null,
        dictionary: 'Gaat het om een LVB-levering?',
        isFocused: false
      },
      lvbNumeric: {
        id: 2,
        name: null,
        dictionary: 'LVB-gegevens',
        isFocused: false,
        dataSource: []
      },
      street: {
        dataSource: [],
        id: 3,
        name: '',
        dictionary: 'Straat',
        isFocused: false
      },
      houseNumber: {
        id: 3,
        name: '',
        dictionary: 'Huisnummer',
        isFocused: false
      },
      addition: {
        id: 4,
        name: '',
        dictionary: 'Toevoeging',
        isFocused: false
      },
      countryIndex: {
        id: 5,
        name: null,
        dictionary: 'Land',
        isFocused: false,
      },
      dhlInsured: {
        id: 7,
        dictionary: 'Wil je jouw binnenlandse verzending laten verzekeren door DHL?',
        name: null,
        isFocused: false,
      },
      dhlInsuredFloat: {
        id: 8,
        dictionary: 'Tot hoeveel € wil je de DHL verzending laten verzekeren?',
        name: null,
        isFocused: false,
      },
      city: {
        id: 9,
        dictionary: 'Stad',
        name: '',
        isFocused: false,
      },
      postcode: {
        id: 9,
        dictionary: 'Postcode',
        name: '',
        isFocused: false,
      },
      remarks: {
        id: 10,
        dictionary: 'Heb je additionele informatie omtrent deze boeking?',
        name: '',
        isFocused: false,
      },
      personalRef: {
        name: '',
        isFocused: false,
        dictionary: 'Persoonlijke referentie',
      },
      bookingReference: {
        dictionary: 'Booking reference',
        name: '',
        isFocused: false,
      },
      userSearch: {
        dictionary: 'Search and select profile',
        name: '',
        isFocused: false,
        dataSource: ['Burns Bay Road', 'Downing Street', 'Wall Street']
      },
      templateData: {
        street: {
          id: 1,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Straat',
          inputSettings: {
            type: 'Input'
          }
        },
        houseNumber: {
          id: 2,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Huisnummer',
          inputSettings: {
            type: 'Input'
          }
        },
        addition: {
          id: 3,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Toevoeging',
          inputSettings: {
            type: 'Input'
          }
        },
        postcode: {
          id: 4,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Postcode',
          inputSettings: {
            type: 'Input'
          }
        },
        city: {
          id: 5,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Stad',
          inputSettings: {
            type: 'Input'
          }
        },
        countryIndex: {
          id: 6,
          name: '',
          renderingCondition: [],
          isFocused: false,
          dictionary: 'Land',
          inputSettings: {
            type: 'Select',
            options: [
              {
                value: '1',
                label: 'Nederland'
              },
              {
                value: '2',
                label: 'België'
              }
            ]
          }
        },
      }
    },
    dashboardInformation: {
      bookingStatus: [],
      transport: [],
      total: '',
    },
    isBookingsLoading: false,
    isFilteredBookingListLoading: false,
    isChangingDeliveryInfo: false,
    bookings: [
      // {
      //   id: null,
      //   status_id: null,
      //   uuid: '',
      //   warehouse_id: null,
      //   products_sum_total_value: '',
      //   status: {
      //     id: null,
      //     name: '',
      //     created_at: '',
      //     updated_at: '',
      //   },
      //   delivery: {
      //     booking_id: null,
      //     company_name: '',
      //     contact: '',
      //     created_at: '',
      //     dhl_insurance: '',
      //     id: null,
      //     lvb: null,
      //     lvb_location: null,
      //     updated_at: null,
      //     address: {
      //       postcode: '',
      //       place: '',
      //       street: '',
      //       delivery_id: null,
      //       created_at: '',
      //       id: null,
      //       updated_at: '',
      //     }
      //   },
      //   products: [
      //     {
      //       actual_cbm: null,
      //       actual_gross_weight: null,
      //       booking_id: null,
      //       cbm: '',
      //       created_at: '',
      //       description: '',
      //       ean_code: '',
      //       gross_weight: '',
      //       hs_code: '',
      //       id: null,
      //       is_dangerous: null,
      //       other_costs: '',
      //       price_per_piece: '',
      //       quantity_boxes: null,
      //       quantity_in_booking: null,
      //       total_value: '',
      //       updated_at: ''
      //     }
      //   ],
      //   supplier: {
      //     booking_id: null,
      //     company_name: '',
      //     created_at: '',
      //     email: '',
      //     id: null,
      //     phone: '',
      //     updated_at: '',
      //     address: {
      //       street: '',
      //       postcode: '',
      //       city: '',
      //       supplier_id: null,
      //       updated_at: '',
      //       created_at: '',
      //       id: null,
      //     }
      //   },
      //   warehouse: {
      //     created_at: '',
      //     id: null,
      //     name: '',
      //     updated_at: '',
      //   },
      // }
    ],
    searchFilter: INITIAL_SEARCH_FILTER(),
    pagination: INITIAL_PAGINATION(),
  },
  mutations: {
    changeStateForTheNextStage(state, {to, newTitle, newSubtitle}) {
      state.currentStage = to
      state.formTitle = newTitle
      state.formSubtitle = newSubtitle
    },
    saveProviderHandler(state, bool) {
      state.mkOne.saveProvider = bool
    },
    saveDeliveryDataHandler(state, bool) {
      state.mkThree.saveDeliveryData = bool
    },
    deleteProduct(state, idx) {
      state.mkTwo.products = [...state.mkTwo.products.filter((obj, index) => index !== idx)]
      if (state.mkTwo.products.length <= 1) {
        state.mkTwo.isDeleteBtnDisabled = true
      }
    },
    deleteSubProduct(state, obj) {
      state.mkTwo.products[obj.productId].subProductObj.product_subs = 
      state.mkTwo.products[obj.productId].subProductObj.product_subs.filter((subProd, idx) => idx !== obj.subProdId)
    },
    makeSupplierList(state, arr) {
      state.mkOne.providerName.dataSource = [...arr]
    },
    makeDeliveryList(state, arr) {
      state.mkThree.street.dataSource = [...arr]
    },
    makeErrorFirstStage(state, err) {
      state.mkOne.errorMessage = err
      setTimeout(() => state.mkOne.errorMessage = '', 6000)
    },
    addProduct(state) {
      state.mkTwo.counter++
      state.mkTwo.products.push({
        ...JSON.parse(JSON.stringify(defaultProductObj)),
        key: state.mkTwo.counter
      })
      if (state.mkTwo.products.length > 1) {
        state.mkTwo.isDeleteBtnDisabled = false
      }
    },
    addSubProduct(state, idx) {
      state.mkTwo.products[idx].subProductObj.counter++;
      state.mkTwo.products[idx].subProductObj.product_subs.push({
          ...defaultSubProductForm(),  
          key: state.mkTwo.products[idx].subProductObj.counter
      }); 
    },
    makeDocumentErrorText (state, { err }) {
      state.mkTwo.document.errorText = []
      err.forEach(er => {
        state.mkTwo.document.errorText.push(er)
      })
      setTimeout(() => state.mkTwo.document.errorText = [], 5000)
    },
    clearDocumentErrors(state) {
      state.mkTwo.document.errorText = []
    },
    goBack(state) {
      state.currentStage--
    },
    isDocumentLoading(state, bool) {
      state.mkTwo.document.isFileLoading = bool
    },
    isDocumentRemoving(state, bool) {
      state.mkTwo.document.isFileRemoving = bool
    },
    putDocumentInState(state, {file, id }) {
      state.mkTwo.document.file.push({file, id})
    },
    doDocumentEmpty(state) {
      state.mkTwo.document.errorText = []
      state.mkTwo.document.isDirty = false
      state.mkTwo.document.loadingFile = false
    },
    clearDocumentFile(state) {
      state.mkTwo.document.file = {}
    },
    changeFormLoadingStatus(state, bool) {
      state.isLoading = bool
    },
    makeFormErrorText(state, msg) {
      state.errorText = msg
      setTimeout(() => state.errorText = '', 6000)
    },
    putSupplierInfo(state, obj) {
      // put new model
      state.mkOne.providerStreetHouseName.name = obj.street || ''
      state.mkOne.recipientAddress.name = obj.city || ''
      state.mkOne.providerEmail.name = obj.email || ''
      state.mkOne.providerPhone.name = obj.phone || ''
      state.mkOne.providerPostCode.name = obj.postcode || ''

      //change focus
      state.mkOne.providerStreetHouseName.isFocused = !!(state.mkOne.providerStreetHouseName.name)
      state.mkOne.recipientAddress.isFocused = !!(state.mkOne.recipientAddress.name)
      state.mkOne.providerEmail.isFocused = !!(state.mkOne.providerEmail.name)
      state.mkOne.providerPhone.isFocused = !!(state.mkOne.providerPhone.name)
      state.mkOne.providerPostCode.isFocused = !!(state.mkOne.providerPostCode.name)

    },
    putDeliveryInfo(state, obj) {
      // put new model
      state.mkThree.houseNumber.name = obj.house_number || ''
      state.mkThree.city.name = obj.city || ''
      state.mkThree.countryIndex.name = +obj.country_index || ''
      state.mkThree.street.name = obj.street || ''
      state.mkThree.postcode.name = obj.post_code || ''
      state.mkThree.addition.name = obj.addition || '' 

      //change focus
      state.mkThree.houseNumber.isFocused = !!(state.mkThree.houseNumber.name)
      state.mkThree.city.isFocused = !!(state.mkThree.city.name)
      state.mkThree.countryIndex.isFocused = !!(state.mkThree.countryIndex.name)
      state.mkThree.street.isFocused = !!(state.mkThree.street.name)
      state.mkThree.postcode.isFocused = !!(state.mkThree.postcode.name)
      state.mkThree.addition.isFocused = !!(state.mkThree.addition.name)
    },
    putBookingsList(state, data) {
      state.bookings = [...Object.values(data)];
    },
    clearBookingsList(state) {
      state.bookings = [];
    },
    putTransportTypeList(state, obj) {
      state.mkOne.transportType.data = []
      Object.keys(obj).forEach(key => {
        if (key !== 'ok' && key !== 'status') {
          state.mkOne.transportType.data.push(obj[key])
        }
      })
    },
    putDahsboardInformation(state, {bookingStatus, total, transport}) {
      state.dashboardInformation = {
        bookingStatus,
        total,
        transport
      }
    },
    clearWarehouseId(state) {
      if (state.mkOne.warhouseId.name === 1 && state.mkOne.transportType.name === 1) return
      if ((state.mkOne.warhouseId.name === 1 || 2) && state.mkOne.transportType.name === 2) return

      state.mkOne.warhouseId.name = null
      state.mkOne.warhouseId.isFocused = false
    },
    putUsersList(state, users) {
      state.mkThree.userSearch.dataSource = users
    },
    putLvbLocationList(state, object) {
      state.mkThree.lvbNumeric.dataSource = []
      Object.keys(object).forEach(key => {
        if (object[key].id) {
          state.mkThree.lvbNumeric.dataSource.push(object[key])
        }
      })
    },
    removeTemplateAddress(state, id) {
      state.mkThree.street.dataSource.filter(address => address.id !== id)
    },
    removeDocument (state, { id }) {
      state.mkTwo.document.file = state.mkTwo.document.file.filter(document => document.id !== id)
    },
    totalProductHandler (state, { total, key, fieldName }) {
      state.mkTwo.products[key][fieldName].name = total.toString();
      state.mkTwo.products[key][fieldName].isFocused = true;
    },
    leaveProductInWarehouseHandler (state, { value, key }) {
      state.mkTwo.products[key].leaveInWarehouse.name = value;
      state.mkTwo.products[key].leaveInWarehouse.isFocused = true;
    },
    clearSubProdWarehouseValue (state, { key, subProdIdx }) {
      state.mkTwo.products[key].subProductObj.product_subs[subProdIdx].quantityInBooking.name = '';
      state.mkTwo.products[key].subProductObj.product_subs[subProdIdx].quantityInBooking.isFocused = false;
    },
    clearSubProdsWhileChangingTransportType (state) {
      state.mkTwo = defaultMkTwoForm();
    },
    changeSubprodFieldCount (state, { key, subProdIdx, fieldName, value }) {
      state.mkTwo.products[key].subProductObj.product_subs[subProdIdx][fieldName].name = value;
      state.mkTwo.products[key].subProductObj.product_subs[subProdIdx][fieldName].isFocused = true;
    },
    clearCustomFields (state, { key, fieldsName }) {
      fieldsName.forEach(fieldName => {
        state.mkTwo.products[key][fieldName].name = null;
        state.mkTwo.products[key][fieldName].isFocused = false;
      })
    },
    autoFillThirdStep (state) {
      Object.keys(autoFillThirdStepData).forEach(fieldName => {
        state.mkThree[fieldName].name = autoFillThirdStepData[fieldName];
        state.mkThree[fieldName].isFocused = true;
      })
    },
    changeTransportType(state, obj) {
      const bookingId = state.bookings.findIndex(({id}) => id === obj.id);
      if (bookingId !== -1) {
        state.bookings.splice(bookingId, 1, obj);
      }
    },
    updatePagination(state, obj) {
      state.pagination = obj;
    },
    clearAutofilledData(state, { step, fields }) {
      fields.forEach(field => {
        state[step][field].name = '';
        state[step][field].isFocused = false;
      })
    },
    editTemplate(state, { step, initialMappedData, data }) {
      Object.keys(initialMappedData).forEach(item => {
        state[step].templateData[initialMappedData[item]].name = data[item];
        state[step].templateData[initialMappedData[item]].isFocused = !!data[item];
      })
    },
    changeLoadingStatus(state, { field, value }) {
      state[field] = value;
    },
    notificationHandler (state, { error, success, key }) {
      error && state.templatesError.push(error);
      makeNotification({
        key,
        message: error ? 'Fout!' : "Succes!",
        type: error ? 'error' : 'success',
        description: error ? error : success,
        duration: 5
      })
    },
    clearErrorAndTempalteNotificationHandler (state) {
      state.templatesError = [];
      closeNotification({ key: EDIT_ADDRESS_TEMPLATE_KEY });
      closeNotification({ key: DELETE_ADDRESS_TEMPLATE_KEY });
      closeNotification({ key: DELETE_SUPPLIER_TEMPLATE_KEY });
      closeNotification({ key: EDIT_SUPPLIER_TEMPLATE_KEY });
    },
    manualUpdatePersonalRef(state, { bookingId, personalRef }) {
      state.bookings?.length && state.bookings.forEach(item => {
        if (item.id === bookingId) {
          item.personal_ref = personalRef;
        }
      })
    },
    clearPersonalRefError (state) {
      state.personalRefError = [];
      closeNotification({ key: EDIT_PERSONAL_REF });
      closeNotification({ key: EDIT_PERSONAL_REF_ERROR });
    },
    clearIncotermsRuleField (state) {
      state.mkOne.incotermsRule.name = null;
      state.mkOne.incotermsRule.isFocused = false;
    },
    clearSearchFilter(state) {
      state.searchFilter = INITIAL_SEARCH_FILTER();
    },
    clearCustomWarehouseAddress(state) {
      state.mkOne.customWarehouseAddress.name = '';
      state.mkOne.customWarehouseAddress.isFocused = false;
    },
  },
  actions: {
    deleteCompanyItem({ dispatch, commit, state }, id) {
      return new Promise((resolve, reject) => {
        FetchAbstraction(true, 'POST', `/api/v1/supplier/layout/delete/${id}`)
          .then((res) => {
            commit('notificationHandler', { success: 'Sjabloon is succesvol verwijderd', key: DELETE_SUPPLIER_TEMPLATE_KEY })
            resolve(res)
        })
          .catch((error) => {
            commit('notificationHandler', { error, key: DELETE_SUPPLIER_TEMPLATE_KEY })
            reject(error)
          })
      })
    },
    async deleteAddressItem({dispatch, commit, state}, id) {
      await FetchAbstraction(true, 'POST', `/api/v1/delivery/layout/delete/${id}`)
        .then((res) => {
          commit('removeTemplateAddress', id)
          commit('notificationHandler', { success: 'Sjabloon is succesvol verwijderd', key: DELETE_ADDRESS_TEMPLATE_KEY })
        })
        .catch((error) => {
          commit('notificationHandler', { error, key: DELETE_ADDRESS_TEMPLATE_KEY })
        })
    },
    setCompanyFromList({ dispatch, commit, state }, { id, isToAutoFillData }) {
      return new Promise((resolve, reject) => {
        commit('changeLoadingStatus', {field: 'isLoadingTemplate', value: true})
        FetchAbstraction(true, 'GET', `/api/v1/supplier/layout/${id}`)
        .then((res) => {
          if (res.status === 200) {
            isToAutoFillData && commit('putSupplierInfo', res);
            !isToAutoFillData && commit('editTemplate', {step: 'mkOne', initialMappedData: SUPPLIER_TEMPLATE_DATA, data: res});
          }
          resolve(res)
        })
          .catch((e) => {
            console.log(e)
            reject(error)
          })
          .finally(() => commit('changeLoadingStatus', {field: 'isLoadingTemplate', value: false}))
      })
    },
    setDeliveryAddressFromList({ dispatch, commit, state }, { id, isToAutoFillData }) {
      return new Promise((resolve, reject) => {
        commit('changeLoadingStatus', {field: 'isLoadingTemplate', value: true})
        FetchAbstraction(true, 'GET', `/api/v1/delivery/layout/${id}`)
        .then((res) => {
          if (res.status === 200) {
            isToAutoFillData && commit('putDeliveryInfo', res);
            !isToAutoFillData && commit('editTemplate', {step: 'mkThree', initialMappedData: DELIVERY_TEMPLATE_DATA, data: res});
          }
          resolve(res)
        })
          .catch((e) => {
            console.log(e)
            reject(error)
          })
        .finally(() => commit('changeLoadingStatus', {field: 'isLoadingTemplate', value: false}))
      })
    },
    editSupplierTemplateItem({ dispatch, commit, state }, id) {
      const data = {};
      Object.keys(SUPPLIER_TEMPLATE_DATA).forEach(item => {
        data[item] = state.mkOne.templateData[SUPPLIER_TEMPLATE_DATA[item]].name;
      });
      return new Promise((resolve, reject) => {
        commit('changeLoadingStatus', {field: 'isChangingTemplateData', value: true});
        FetchAbstraction(true, 'POST', `/api/v1/supplier/layout/update/${id}`, data)
          .then((res) => {
            if (!res || res.message !== "Success") {
              return
            }
            commit('notificationHandler', { success: 'Sjabloon is succesvol gewijzigd', key: EDIT_SUPPLIER_TEMPLATE_KEY })
          resolve()
        })
          .catch((error) => {
            commit('notificationHandler', { error, key: EDIT_SUPPLIER_TEMPLATE_KEY })
            reject(error)
          })
        .finally(() => commit('changeLoadingStatus', {field: 'isChangingTemplateData', value: false}))
      })
    },
    editDeliveryTemplateItem({ dispatch, commit, state }, id) {
      const data = {};
      Object.keys(DELIVERY_TEMPLATE_DATA).forEach(item => {
        data[item] = state.mkThree.templateData[DELIVERY_TEMPLATE_DATA[item]].name;
      });
      return new Promise((resolve, reject) => {
        commit('changeLoadingStatus', {field: 'isChangingTemplateData', value: true});
        FetchAbstraction(true, 'POST', `/api/v1/delivery/layout/update/${id}`, data)
          .then((res) => {
            if (!res || res.message !== "Success") {
              return
            }
            commit('notificationHandler', { success: 'Sjabloon is succesvol gewijzigd', key: EDIT_ADDRESS_TEMPLATE_KEY })
            resolve()
        })
          .catch((error) => {
            commit('notificationHandler', { error, key: EDIT_ADDRESS_TEMPLATE_KEY })
            reject(error)
          })
          .finally(() => commit('changeLoadingStatus', {field: 'isChangingTemplateData', value: false}))
        })
    },
    async getCompaniesList({ dispatch, commit, state }) {
      let arr = [];
      commit('makeSupplierList', arr);
      await FetchAbstraction(true, 'GET', '/api/v1/supplier/layout/list')
        .then((res) => {
          if (res.status === 200) {
            Object.keys(res).forEach(key => {
              if (res[key].id) {
                arr.push({
                  id: res[key].id,
                  name: res[key].name
                })
              }
            })
          }
        })
        .then(() => commit('makeSupplierList', arr))
        .catch((e) => console.log(e))
    },
    async getDeliveryAddressList({dispatch, commit, state}) {
      let arr = []
      commit('makeDeliveryList', arr);
      await FetchAbstraction(true, 'GET', '/api/v1/delivery/layout/list')
        .then((res) => {
          if (res.status === 200) {
            Object.keys(res).forEach(key => {
              if (res[key].id) {
                arr.push({
                  id: res[key].id,
                  street: res[key].street
                })
              }
            })
          }
        })
        .then(() => commit('makeDeliveryList', arr))
        .catch((e) => console.log(e))
    },
    async getTransportTypeList({dispatch, commit, state}) {
      await FetchAbstraction(true, 'GET', '/api/v1/transporttype/list')
        .then((res) => {
          if (res.ok) {
            commit('putTransportTypeList', res)
          }
        })
        .catch((e) => console.log(e))
    },
    async getLvbLocationList({dispatch, commit, state}) {
      await FetchAbstraction(true, 'GET', '/api/v1/lvb-location/list')
        .then((res) => {
          if (res.ok) {
            commit('putLvbLocationList', res)
          }
        })
        .catch((e) => console.log(e))
    },
    async saveRecipient({dispatch, commit, state}) {
      let recipientFields = {
        name: state.mkOne.providerName.name,
        city: state.mkOne.recipientAddress.name,
        street: state.mkOne.providerStreetHouseName.name,
        email: state.mkOne.providerEmail.name,
        postcode: state.mkOne.providerPostCode.name,

      }
      if (state.mkOne.providerPhone.name) {
        recipientFields.phone = state.mkOne.providerPhone.name
      }
      return await FetchAbstraction(true, 'POST', '/api/v1/supplier/layout/create', recipientFields)
        .then((res) => {
          if (!res.ok) {
            commit('makeErrorFirstStage', res.message)
          }
        })
        .catch((e) => console.log(e))
    },
    async saveDeliveryData({dispatch, commit, state}) {
      let deliveryDataFields = {
        city: state.mkThree.city.name || '',
        country_index: state.mkThree.countryIndex.name.toString() || '',
        street: state.mkThree.street.name || '',
        house_number: state.mkThree.houseNumber.name || '',
        post_code: state.mkThree.postcode.name || '',
        addition: state.mkThree.addition.name || ''
      }
      return await FetchAbstraction(true, 'POST', '/api/v1/delivery/layout/create', deliveryDataFields)
        .then((res) => {
          if (!res.ok) {
            commit('makeErrorFirstStage', res.message)
          }
        })
        .catch((e) => console.log(e))
    },
    async firstStage({dispatch, commit, state}) {
      commit('changeStateForTheNextStage', {
        to: 2,
        newTitle: 'Productgegevens',
        newSubtitle: 'Voer hier de gegevens in van de product(en) die je wilt importeren'
      })
    },
    async secondStage({dispatch, commit, state}) {
      commit('changeStateForTheNextStage', {
        to: 3,
        newTitle: 'Levering',
        newSubtitle: 'Voer hier de gegevens in voor de levering van je goederen'
      })
    },
    async thirdStage({dispatch, commit, state}) {
      commit('changeStateForTheNextStage', {
        to: 4,
        newTitle: 'Overzicht boeking',
        newSubtitle: 'Controleer en bevestig je ingevoerde gegevens, dit is hierna niet meer aan te passen'
      })
    },
    async fourthStage({dispatch, commit, state, rootState}) {
      commit('changeFormLoadingStatus', true)
      let beautifiedProductsArr = state.mkTwo.products.map(obj => {
        let object = {
          description: obj.description.name,
          hs_code: obj.hsCode.name,
          price_per_piece: +obj.pricePerPiece.name.replace(',', '.'),
          other_costs: +obj.otherCosts.name.replace(',', '.'),
          total_value: +obj.totalValue.name.replace(',', '.'),
          quantity_in_booking: +obj.quantityInBooking.name.replace(',', '.'),
          quantity_boxes: +obj.quantityBoxes.name.replace(',', '.'),
          gross_weight: +obj.grossWeight.name.replace(',', '.'),
          cbm: +obj.cbm.name.replace(',', '.'),
          is_dangerous: !!obj.isDangerous.name,
          ...(obj.enaCode.name && { ean_code: obj.enaCode.name}),
          ...(obj.leaveInWarehouse.name && state.mkOne.transportType.name === 2 && { warehouse_boxes_count: +obj.countBoxesToLeaveInWarehouse.name }),
          product_subs: obj.subProductObj.product_subs.length && obj.subProductObj.product_subs.map(subProduct => {
            return {
              "description": subProduct.description.name || null,
              "boxes": +subProduct.quantityBoxes.name || null,
              "warehouse_boxes": +subProduct.quantityInBooking.name || null
            }
          }) || []
        }
        return object
      })
      let booking = {
        warehouse_id: state.mkOne.warhouseId.name,
        transport_type_id: state.mkOne.transportType.name,
        incoterm_id: state.mkOne.incotermsRule.name,
        remarks: state.mkThree.remarks.name,
        personal_ref: state.mkThree.personalRef.name,
        delivery: {
          lvb: !!state.mkThree.lvb.name,
          contact: rootState.userInfo.name,
          company_name: rootState.companyInfo.name,
          ...(state.mkThree.dhlInsured.name && { dhl_insurance: +state.mkThree.dhlInsuredFloat.name.replace(',', '.') }),
          address: {
            ...(!state.mkThree.lvb.name && state.mkThree.addition.name && { addition: state.mkThree.addition.name })
          }
        },
        supplier: {
          company_name: state.mkOne.providerName.name,
          email: state.mkOne.providerEmail.name,
          ...(state.mkOne.providerPhone.name && { phone: state.mkOne.providerPhone.name }),
          address: {
            street: state.mkOne.providerStreetHouseName.name,
            city: state.mkOne.recipientAddress.name,
            ...(state.mkOne.providerPostCode.name && { postcode: state.mkOne.providerPostCode.name })
          }
        },
        products: beautifiedProductsArr,
        warehouse_custom: state.mkOne.customWarehouseAddress.name,
      }

      if (state.mkThree.lvbNumeric.name) {
        let obj = JSON.parse(state.mkThree.lvbNumeric.name)
        booking.delivery.lvb_location = obj.id
        booking.delivery.address = {
          place: obj.city,
          country: obj.country,
          house_number: obj.house_number,
          postcode: obj.postcode,
          street: obj.street
        }
        if (obj.addition) booking.delivery.address.addition = obj.addition
        booking.country_index_id = obj.country_index_id
      }

      if (!state.mkThree.lvb.name) {
        booking.delivery.address.country = state.mkThree.countryIndex.name === 1 ? 'Netherlands' : 'Belgium'
        booking.delivery.address.street = state.mkThree.street.name
        booking.country_index_id = state.mkThree.countryIndex.name
        booking.delivery.address.place = state.mkThree.city.name
        booking.delivery.address.house_number = state.mkThree.houseNumber.name
        booking.delivery.address.postcode = state.mkThree.postcode.name
      }

      if (state.mkThree.userSearch.name || state.mkThree.bookingReference.name) {
          let obj = {}
          state.mkThree.userSearch.name && (obj.user_id = +state.mkThree.userSearch.name)
          state.mkThree.bookingReference.name && (obj.ref = state.mkThree.bookingReference.name)
          booking.admin = obj
      }
      return await FetchAbstraction(true, 'POST', '/api/v1/booking/create', booking)
        .then((res) => {
          if (!res || res.message !== 'Success' || res.status !== 200) {
            commit('makeFormErrorText', res.message || 'something went wrong')
            return 
          }
          commit('changeStateForTheNextStage', {
            to: 5,
            newTitle: '',
            newSubtitle: ''
          })
          return
        })
        .catch((e) => console.log(e))
        .finally(() => {
          commit('changeFormLoadingStatus', false)
        })
    },
    async validateDocument({dispatch, commit, state}, {file}) {
      commit('isDocumentLoading', true)
      const formData = new FormData()
      formData.append('document', file)
      return await FetchAbstraction(true, 'POST', '/api/v1/booking/document/upload', formData)
        .then((res) => {
          if (!res.ok && res.document) {
            commit('makeDocumentErrorText', {err: res.document});
            return
          }
          if (!res.ok && res.message) {
            commit('makeDocumentErrorText', {err: [res.message]});
            return
          }
          commit('putDocumentInState', {file: file, id: res.uniqId})
        })
        .catch((e) => console.log(e))
        .finally(() => commit('isDocumentLoading', false))
    },
    async removeDocument({ dispatch, commit, state }, id) {
      commit('isDocumentRemoving', true)

      await FetchAbstraction(true, 'POST', `/api/v1/booking/document/remove`, { uniqId: id })
      .then(res => {
        if (!res.ok) {
          commit('makeDocumentErrorText', {err: res.document})
          return
        }
        commit('removeDocument', { id });
      })
      .catch((e) => console.log(e))
      .finally(() => commit('isDocumentRemoving', false))
    },
    async getBookings({ dispatch, commit, state }, { currentPage, itemsPerPage }) { 
      state.isBookingsLoading = true;
      await FetchAbstraction(true, 'GET', `/api/v1/booking/list?page=${currentPage}&per_page=${itemsPerPage}`)
        .then((res) => {
          if (res.status === 200) {
            const paginationData = {
              totalItems: res.total,
              itemsPerPage: res.per_page,
              currentPage: res.current_page,
            };
            commit('putBookingsList', res.data);
            commit('updatePagination', paginationData)
          }
        })
        .catch(e => {
          console.log('Downloading bookings failed', e);
          return;
        })
        .finally(e => state.isBookingsLoading = false)
    },
    // async getBookingById({dispatch, commit, state}, id) {
    //   return await FetchAbstraction(true, 'GET', `/api/v1/booking/${id}`)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         return res
    //       }
    //     })
    //     .catch((e) => console.log(e))
    // },
    async updateCompanyInformation({dispatch, commit, state}, obj) {
      let companyPayload = {
        name: obj.companyName.name,
        vat_number: obj.btwNumber.name,
        eori_number: obj.eoriNumber.name,
        kbo_number: obj.kvkNumber.name,
        address: {
          place: obj.cityName.name,
          street: obj.streetHouseName.name,
          house_number: obj.houseNumber.name,
          addition: obj.addition.name,
          postcode: obj.postCode.name,
        }
      }
      return await FetchAbstraction(true, 'POST', `/api/v1/company/update`, companyPayload)
        .then((res) => {
          return res
        })
        .catch((e) => console.log(e))
    },
    async updateCompanyDocument({dispatch, commit, state}, {fileObj, title}) {
      let type = title === 'kvk' ? 'chamber-of-commerce' : 'direct-representation'
      let formData = new FormData()
      formData.set('document', fileObj)
      formData.set('type', type)

      return await FetchAbstraction(true, 'POST', `/api/v1/company/document/update`, formData)
        .then((res) => {
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async updateAccountInformation({dispatch, commit, state}, {oldPassword, newPassword, repeatPassword}) {
      let obj = {
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: repeatPassword
      }
      return await FetchAbstraction(true, 'POST', `/api/v1/user/update/password`, obj)
        .then((res) => {
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async verificatePhone({dispatch, commit, state}, {number, code}) {
      let obj = {
        phone: {
          code,
          number
        }
      }
      return await FetchAbstraction(true, 'POST', `/api/v1/verification/phone`, obj)
        .then((res) => {
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async verificateCode({dispatch, commit, state}, {number, phoneCode, code}) {
      let obj = {
        phone: {
          code: phoneCode,
          number
        },
        code
      }
      return await FetchAbstraction(true, 'POST', `/api/v1/verification/phone/code`, obj)
        .then((res) => {
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async updatePersonalInformation({dispatch, commit, state, rootState}, {code, number, email, name, surname, position}) {
      let obj = {
        name,
        surname
      }
      if (email !== rootState.userInfo.email) {
        obj.email = email
      }
      if (number !== rootState.userInfo.phone.number || code !== rootState.userInfo.phone.code) {
        obj.phone = {
          number,
          code,
        }
      }

      if (position !== rootState.userInfo.position) {
        obj.position = position
      }
      return await FetchAbstraction(true, 'POST', `/api/v1/user/update`, obj)
        .then((res) => {
          dispatch('getUser')
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async updateAvatar({dispatch, commit, state}, file) {
      let formData = new FormData()
      formData.set('photo', file)
      return await FetchAbstraction(true, 'POST', `/api/v1/user/photo/upload`, formData)
        .then((res) => {
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async getDashboardInformation({dispatch, commit, state}) {
      return await FetchAbstraction(true, 'GET', `/api/v1/dashboard/main`)
        .then((res) => {
          if (res.ok) {
            commit('putDahsboardInformation', res)
          }
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async getUsersList({dispatch, commit, state}) {
      return await FetchAbstraction(true, 'GET', `/api/v1/users/list`)
        .then((res) => {
          if (res.ok) {
            let makeArr = []
            Object.keys(res).forEach(key => {
              if (key !== 'ok' && key !== 'status') makeArr.push(res[key])
            })
            commit('putUsersList', makeArr)
          }
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async cancelBooking({dispatch, commit, state}, bookingId) {
      return await FetchAbstraction(true, 'POST', `/api/v1/booking/${bookingId}/cancel`)
        .then((res) => {
          return res
        })
        .catch((e) => {
          throw e
        })
    },
    async searchBooking({dispatch, commit, state}, { currentPage, itemsPerPage }) {
      state.isFilteredBookingListLoading = true;
      const filter = {
        searchString: state.searchFilter.searchString,
        bookingStatus: state.searchFilter.bookingStatus
      };
      return await FetchAbstraction(true, 'POST', `/api/v1/booking/search?page=${currentPage}&per_page=${itemsPerPage}`, filter)
        .then((res) => {
          if (res.status === 200) {
            const paginationData = {
              totalItems: res.total,
              itemsPerPage: res.per_page,
              currentPage: res.current_page,
            }
            commit('putBookingsList', res.data)
            commit('updatePagination', paginationData)
          }
        })
        .catch(e => {
          console.log('Downloading bookings failed', e);
          return;
        })
        .finally(() => state.isFilteredBookingListLoading = false)
    },
    async changeDeliveryInfo({dispatch, commit, state}, { bookingId, transportTypeId, incotermRuleId }) {
      state.isChangingDeliveryInfo = true;
      const options = {
        transport_type_id: transportTypeId,
        incoterm_id: incotermRuleId,
      };
      return await FetchAbstraction(true, 'POST', `/api/v1/booking/change/${bookingId}`, options)
        .then((res) => {
          if (res.status === 200) {
            commit('changeTransportType', res[0]);
            return;
          }
        })
        .catch((e) => {
          throw e
        })
        .finally(() => state.isChangingDeliveryInfo = false)
    },
    updatePersonalRef({ dispatch, commit, state }, { bookingId, personal_ref = '' }) {
      const options = {
        personal_ref
      };
      commit('clearPersonalRefError');
      return new Promise((resolve, reject) => {
        FetchAbstraction(true, 'POST', `/api/v1/booking/edit_ref/${bookingId}`, options)
          .then((res) => {
            if (res?.ok) {
              commit('notificationHandler', { success: 'Persoonlijke referentie is succesvol gewijzigd', key: EDIT_PERSONAL_REF })
              resolve();
            } else {
              commit('notificationHandler', { error, key: EDIT_PERSONAL_REF_ERROR })
              reject();
            }
          })
          .catch((error) => {
            commit('notificationHandler', { error, key: EDIT_PERSONAL_REF_ERROR })
            reject();
          })
        }
    )}
  },
  getters: {
    getProducts(state, productIdx, subproductIdx) {
      return state.mkTwo.products[productIdx];
    },
  }
}
