<template>
  <div class="registerWrapper">
    <RegistrationBenefits/>
    <div class="formWrapper">
      <div class="formWrapper__toLoginLink">
        Ik heb al een account
        <router-link
          to="/"
          class="formWrapper__toLoginLink"
        > Inloggen
        </router-link>
      </div>
      <div class="formWrapper__registrationGuide">
        <h2>Registreer jouw account</h2>
        <p>
          Wil je meer weten over hoe je een account moet aanmaken? Bekijk dan deze handleiding; <a :href="vueApiUrl" target="_blank">Handleiding Account registratie</a>
        </p>
      </div>
      <form
        v-if="!isFormCompleted"
        class="form">
        <!-- <div class="form__progress">
          <div class="form__progress--title">
            <h2>
              {{ formProgressTitle }}
              Persoonlijke gegevens
            </h2>
            <p>
              <span>{{ currentFormStage }}</span>/{{ formStageCount }}
            </p>
          </div>
          <a-progress
            type="line"
            size="small"
            strokeColor="#FFA300"
            :percent="(currentFormStage / formStageCount) * 100"
            :show-info="false"
          />
          <p class="form__privacy">
            {{formProgressSubtitle}}
          </p>
        </div> -->
        <div>
          <RegistrationFirstStep
            v-show="currentFormStage === 1"
            :shouldCheckValidate="shouldCheckValidateFirstStage"
            @validationFailed="shouldCheckValidateFirstStage = false"
            @validationSuccess="nextStageHandler"
          />
          <!-- <RegistrationSecondStep
            v-show="currentFormStage === 2"
            :shouldCheckValidate="shouldCheckValidateSecondStage"
            @validationFailed="shouldCheckValidateSecondStage = false"
            @validationSuccess="nextStageHandler"
          />
          <RegistrationThirdStep
            v-show="currentFormStage === 3"
            :shouldCheckValidate="shouldCheckValidateThirdStage"
            @validationFailed="shouldCheckValidateThirdStage = false"
            @validationSuccess="nextStageHandler"
          /> -->

          <a-alert
            style="margin-top: 20px"
            :message="registerState.errorOnCreating"
            type="error"
            close-text="Close Now"
            v-if="registerState.errorOnCreating.length"
          />
          <a-button-group size="large" class="form__steps">
            <a-button type="primary"
                      @click="nextStageHandler"
                      :disabled="(currentFormStage === 1 && firstStep.phone.loading) || registerState.isLoading"
                      :loading="(currentFormStage === 1 && firstStep.phone.loading) || registerState.isLoading"
            >
              Volgende stap
              <a-icon type="right"/>
            </a-button>
          </a-button-group>
          <a-modal
            :visible="firstStep.isModalConfirmPhoneVisible"
            :confirm-loading="firstStep.isModalConfirmPhoneLoading"
            @cancel="cancelModal"
            :footer="null"
            :closable="false"
          >
            <PhoneConfirmModal
              :firstStep="firstStep"
              :focusHandler="focusHandler"
              :sendCodeAgain="sendCodeAgain"
              :modalVerifienPhone="modalVerifienPhone"
              :cancelModal="cancelModal"
              :changePhone="changePhone"
            />
          </a-modal>
        </div>
      </form>
      <RegistrationFinished v-if="isFormCompleted"/>

      <RegistrationLoginFooter/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import focus from "@/mixins/focus";
import PhoneConfirmModal from "@/components/PhoneConfirmModal";
import RegistrationBenefits from "@/components/RegistrationBenefits";
import RegistrationFirstStep from "@/components/RegistrationFirstStep";
import RegistrationSecondStep from "@/components/RegistrationSecondStep";
import RegistrationThirdStep from "@/components/RegistrationThirdStep";
import RegistrationLoginFooter from "@/components/RegistrationLoginFooter";
import RegistrationFinished from "@/components/RegistrationFinished";

export default {
  components: {
    PhoneConfirmModal,
    RegistrationBenefits,
    RegistrationFirstStep,
    RegistrationSecondStep,
    RegistrationThirdStep,
    RegistrationLoginFooter,
    RegistrationFinished
  },
  mixins: [focus],
  computed: {
    ...mapState({
      firstStep: state => state.register.firstStep,
      registerState: state => state.register,
      // secondStep: state => state.register.secondStep,
      // thirdStep: state => state.register.thirdStep,
      // formStageCount: state => state.register.formStageCount,
      // formProgressTitle: state => state.register.formProgressTitle,
      // formProgressSubtitle: state => state.register.formProgressSubtitle,
      currentFormStage: state => state.register.currentFormStage,
      isFormCompleted: state => state.register.isFormCompleted,
    }),
    vueApiUrl() {
      return `${process.env.VUE_APP_API_URL}/registration/manual`
    }
  },
  data() {
    return {
      loading: false,
      shouldCheckValidateFirstStage: false,
      // shouldCheckValidateSecondStage: false,
      // shouldCheckValidateThirdStage: false
    }
  },
  methods: {
    ...mapActions([
      'firstStepValidation',
      // 'secondStepValidation',
      // 'thirdStepValidation',
      'sendCodeAgain',
      'modalVerifienPhone',
      'cancelModal',
      // 'backButtonHandler',
      'changePhone'
    ]),
    nextStageHandler() {
      if (this.currentFormStage === 1) {
        this.shouldCheckValidateFirstStage = true
        if (this.firstStep.isStepFormValid) {
          this.firstStepValidation()
            .catch(e => console.log(e))
        }
      }
      // if (this.currentFormStage === 2) {
      //   this.shouldCheckValidateSecondStage = true
      //   if (this.secondStep.isStepFormValid) {
      //     this.secondStepValidation()
      //     return
      //   }
      // }
      // if (this.currentFormStage === 3) {
      //   this.shouldCheckValidateThirdStage = true
      //   if (this.thirdStep.isStepFormValid) {
      //     this.thirdStepValidation()
      //       .then(obj => {
      //         console.log(obj)
      //         this.$router.push('/dashboard')
      //       })
      //       .catch(e => console.log(e))
      //   }
      // }
    },
  }
}
</script>

<style scoped lang="scss">
.registerWrapper {
  display: flex;
  background: rgba(blue, 0.02);
  justify-content: center;
  min-height: 100vh;
}


.formWrapper {
  width: 50%;
  max-width: 700px;
  margin: 40px 0 40px 100px;
}

.formWrapper__form {
  &--label.focused {
    background: rgb(250, 250, 255);
  }
}

.formWrapper__toLoginLink {
  text-align: end;
  margin-bottom: 40px;
}

.form__progress {
  &--title {
    display: flex;
    justify-content: space-between;

    h2 {
      color: #FFA300;
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;
    }

    p {
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;

      span {
        color: #FFA300;
      }
    }
  }
}

.form__verifyCodeNumber {
  display: flex;
  justify-content: space-between;
}

.form__inlineInputs {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 45%;
  }

}

.form__steps {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 40px 0;
}


</style>


