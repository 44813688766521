<template>
  <div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.firstStep.name.name.$dirty && $v.firstStep.name.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.firstStep.name.name.$dirty && $v.firstStep.name.name.$invalid"
            >
              {{ `Onjuiste invoer` }}
            </span>
          </template>
          <a-input
            allow-clear
            id="name"
            v-model="firstStep.name.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('name', true, 'firstStep')"
            @blur="focusHandler('name', false, 'firstStep')"
          />
        </a-form-item>

        <label for="name"
               class="formWrapper__form--label"
               :class="firstStep.name.isFocused ? 'focused' : ''"
        >
          Voornaam
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.firstStep.surname.name.$dirty && $v.firstStep.surname.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.firstStep.surname.name.$dirty && $v.firstStep.surname.name.$invalid"
            >
              {{ `Onjuiste invoer` }}
            </span>
          </template>
          <a-input
            allow-clear
            id="surname"
            v-model="firstStep.surname.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('surname', true, 'firstStep')"
            @blur="focusHandler('surname', false, 'firstStep')"
          />
        </a-form-item>

        <label for="name"
               class="formWrapper__form--label"
               :class="firstStep.surname.isFocused ? 'focused' : ''"
        >
          Achternaam
        </label>
      </div>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.firstStep.email.name.$dirty && $v.firstStep.email.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
          <span
            v-show="$v.firstStep.email.name.$dirty && $v.firstStep.email.name.$invalid"
          >
            {{ firstStep.email.errorText || 'Onjuiste invoer'}}
          </span>
          </template>
          <a-input-search
            allow-clear
            id="email"
            :loading="firstStep.email.loading"
            v-model="firstStep.email.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('email', true, 'firstStep')"
            @blur="focusHandler('email', false, 'firstStep')"
          />
        </a-form-item>

        <label for="email"
               class="formWrapper__form--label"
               :class="firstStep.email.isFocused ? 'focused' : ''"
        >
          E-mailadres
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.firstStep.positionInCompany.name.$dirty && $v.firstStep.positionInCompany.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.firstStep.positionInCompany.name.$dirty && $v.firstStep.positionInCompany.name.$invalid"
            >
               Onjuiste invoer
            </span>
          </template>
          <a-input
            allow-clear
            id="positionInCompany"
            v-model="firstStep.positionInCompany.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('positionInCompany', true, 'firstStep')"
            @blur="focusHandler('positionInCompany', false, 'firstStep')"
          />
        </a-form-item>

        <label for="positionInCompany"
               class="formWrapper__form--label"
               :class="firstStep.positionInCompany.isFocused ? 'focused' : ''"
        >
          Functie in bedrijf
        </label>
      </div>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="($v.firstStep.phone.name.$dirty && $v.firstStep.phone.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="$v.firstStep.phone.name.$dirty && $v.firstStep.phone.name.$invalid"
          >
            {{ `Onjuiste invoer` }}
          </span>
          <span
            v-show="!($v.firstStep.phone.name.$dirty && $v.firstStep.phone.name.$invalid)"
          >
            {{ 'We sturen je een SMS om je telefoonnummer te verifiëren' }}
          </span>
        </template>
        <a-input
          allow-clear
          id="phone"
          v-model="firstStep.phone.name"
          size="large"
          class="formWrapper__form--input"
          @input="changePhone"
          @focus="focusHandler('phone', true, 'firstStep')"
          @blur="focusHandler('phone', false, 'firstStep')"
        >
          <a-select
            slot="addonBefore"
            style="width: 70px"
            @change="(val) => firstStep.phone.code = val"
            default-value="31"
          >
            <a-select-option value="31">
              <span class="notranslate" translate="no">+31</span>
            </a-select-option>
            <a-select-option value="32">
              <span class="notranslate" translate="no">+32</span>
            </a-select-option>
            <a-select-option value="380" v-if="!isProductionMode">
              <span class="notranslate" translate="no">+380</span>
            </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>

      <label for="name"
             class="formWrapper__form--label formWrapper__form--label--phone"
             :class="firstStep.phone.isFocused ? 'focused' : ''"
      >
        Telefoonnummer
      </label>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.firstStep.password.name.$dirty && $v.firstStep.password.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.firstStep.password.name.$dirty && $v.firstStep.password.name.$invalid"
            >
              {{ `Het wachtwoord moet minimaal 10 tekens bevatten` }}
            </span>
          </template>
          <a-input-password
            allow-clear
            id="password"
            v-model="firstStep.password.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('password', true, 'firstStep')"
            @blur="focusHandler('password', false, 'firstStep')"
          />
        </a-form-item>

        <label for="name"
               class="formWrapper__form--label"
               :class="firstStep.password.isFocused ? 'focused' : ''"
        >
          Wachtwoord
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.firstStep.repeatPassword.name.$dirty && $v.firstStep.repeatPassword.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.firstStep.repeatPassword.name.$dirty && $v.firstStep.repeatPassword.name.$invalid"
            >
              {{ `Het wachtwoord moet minimaal 10 tekens bevatten` }}
              {{ $v.firstStep.repeatPassword.sameAsPassword }}
            </span>
          </template>
          <a-input-password
            allow-clear
            id="repeatPassword"
            v-model="firstStep.repeatPassword.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('repeatPassword', true, 'firstStep')"
            @blur="focusHandler('repeatPassword', false, 'firstStep')"
          />
        </a-form-item>

        <label for="name"
               class="formWrapper__form--label"
               :class="firstStep.repeatPassword.isFocused ? 'focused' : 'sdcc'"
        >
          Herhaal wachtwoord
        </label>
      </div>
    </div>
    <div class="form__checkbox">
      <a-checkbox
        id="termsCheckbox"
        @change="checkboxHandler"

      >
        Ja, ik ga akkoord met de
        <a href="https://api.import4you.nl/terms-and-conditions" target="_blank" rel="noopener noreferrer">Algemene Voorwaarden</a>
      </a-checkbox>
      <p v-show="$v.firstStep.isAgreeWithTerms.$invalid && $v.firstStep.isAgreeWithTerms.$dirty"
         style="color: red"
      >
        {{ 'Dit veld is verplicht' }}
      </p>
    </div>
    <div v-show="firstStep.phoneValidationErrorText" style="margin-top: 20px">
      <a-alert :message="firstStep.phoneValidationErrorText"
               type="error"
               show-icon
      />
    </div>
  </div>
</template>

<script>
import {email, minLength, required, sameAs, numeric, maxLength} from "vuelidate/lib/validators";
import {mapActions, mapMutations, mapState} from 'vuex';
import focus from "@/mixins/focus";
import FetchAbstraction from "@/utils/FetchAbstraction";


export default {
  name: 'RegistrationFirstStep',
  mixins: [focus],
  props: {
    shouldCheckValidate: Boolean
  },
  watch: {
    shouldCheckValidate(val) {
      if (val === true) {
        this.checkValidate()
      }
    }
  },
  computed: {
    ...mapState({
      firstStep: state => state.register.firstStep,
    }),
    isProductionMode() {
      return process.env.NODE_ENV === 'production'
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  validations: {
    firstStep: {
      name: {
        name: {
          required,
          minLength: minLength(2)
        }
      },
      surname: {
        name: {
          required,
          minLength: minLength(2)
        }
      },
      positionInCompany: {
        name: {
          required,
          maxLength: maxLength(30)
        }
      },
      phone: {
        name: {
          required,
          numeric,
          maxLength: maxLength(10),
          minLength: minLength(7)
        },
        code: {
          required
        }
      },
      email: {
        name: {
          required,
          email,
          maxLength: maxLength(250),
          isEmailInvalid(email) {
            this.firstStep.email.loading = true

            let userEmail = {
              email: email,
            };
            return FetchAbstraction(false, 'POST', '/api/v1/validation/user', userEmail).then((res) => {
              if (res.message === 'success') {
                return true
              } else if (res.email && res.email.length) {
                this.firstStep.email.errorText = res.email[0]
                this.$emit('validationFailed')
                return false
              }
            })
              .catch((e) => console.log(e))
              .finally(() => this.firstStep.email.loading = false)
          }
        }
      },
      password: {
        name: {
          required,
          minLength: minLength(10)
        }
      },
      repeatPassword: {
        name: {
          required,
          minLength: minLength(10),
          sameAsPassword: sameAs(function () {
            return this.firstStep.password.name;
          })
        }
      },
      isAgreeWithTerms: {
        sameAs: sameAs(() => true)
      },
    },
  },
  methods: {
    ...mapActions([
      'sendCodeAgain',
      'cancelModal',
      'changePhone',
    ]),
    ...mapMutations([
      'agreeTermsHandler'
    ]),
    checkboxHandler(e) {
      this.$v.firstStep.isAgreeWithTerms.$touch()
      this.agreeTermsHandler(e.target.checked)
    },
    checkValidate() {
      this.$v.firstStep.$touch()
      if (this.$v.firstStep.$invalid) {
        this.$emit('validationFailed')
        return;
      }
      this.firstStep.isStepFormValid = true
      this.$emit('validationSuccess')
    }
  }
}
</script>
