import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuelidate from "vuelidate";

import { Empty, Alert, Button, Icon, Progress, Form, AutoComplete, Select, Input, Checkbox, Modal, Tooltip, Spin, Collapse, Card, List, Upload, Badge  } from 'ant-design-vue';

const antdComponents = [Empty, Alert, Button, Icon, Progress, Form, AutoComplete, Select, Input, Checkbox, Modal, Tooltip, Spin, Collapse, Card, List, Upload, Badge];

antdComponents.map(component => {
  Vue.use(component);
});
Vue.config.productionTip = false
Vue.use(Vuelidate)

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export { vm }
