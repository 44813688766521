<template>
  <div class="modal">
    <h2>
      Telefoonnummer verificatie
    </h2>
    <p>
      We hebben je een sms gestuurd om je telefoonnummer ({{firstStep.phone.code + firstStep.phone.name}})  te kunnen verifiëren.
    </p>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
          :validate-status="firstStep.sixCode.errorText ? 'error' : 'success'"
      >
        <template #help>
          <span
              v-show="firstStep.sixCode.errorText"
          >
              {{firstStep.sixCode.errorText}}
          </span>
        </template>
        <a-input
            allow-clear
            id="sixCode"
            v-model="firstStep.sixCode.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('sixCode', true, 'firstStep')"
            @blur="focusHandler('sixCode', false, 'firstStep')"
        />
      </a-form-item>
      <label for="sixCode"
             class="formWrapper__form--label"
             :class="firstStep.sixCode.isFocused ? 'focused' : ''"
      >
        Voer de 6-cijferige code in
      </label>
    </div>
    <p>
      Geen code ontvangen? <a @click="sendCodeAgain">Verstuur opnieuw</a>
    </p>
    <a-button-group size="large" class="form__verifyCodeNumber">
      <a-button type="primary"
                @click="modalVerifienPhone"
      >
        Verifiëren
        <a-icon type="right"/>
      </a-button>
      <a-button type="link"
                @click="cancelModal">
        Verander telefoonnummer
      </a-button>
    </a-button-group>
  </div>
</template>

<script>
export default {
  name: 'PhoneConfirmModal',
  props: {
    firstStep: Object,
    focusHandler: Function,
    sendCodeAgain: Function,
    modalVerifienPhone: Function,
    cancelModal: Function,
  }

}
</script>
