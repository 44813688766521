<template>
  <div class="footer"
       :style="customStyle"
  >
    <ul class="footer__list">
      <li class="footer__list--item">
        <a :href="termsAndCons" target="_blank">Algemene Voorwaarden</a>
      </li>
      <li class="footer__list--item">
        <a href="https://import4you.nl/privacy-policy/" target="_blank">Privacy Verklaring</a>
      </li>
      <li class="footer__list--item">
        <a href="https://import4you.nl/veelgestelde-vragen/" target="_blank">FAQ</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: ['customStyle'],
    name: 'RegistrationLoginFooter',
    computed: {
      termsAndCons() {
        return `${process.env.VUE_APP_API_URL}/terms-and-conditions`;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    bottom: 10px;
    width: 100%;
    max-width: 700px;
    margin-top: 40px;
  }
  .footer__list {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-around;
  }
  .footer__list--item {
    font-weight: bold;

    &:first-letter {
      text-transform: uppercase;
    }
  }
</style>
