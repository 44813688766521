import {vm} from "@/main"

async function saveToken(obj) {
  localStorage.setItem('token', obj.token)
  let expireDate = obj.ttl * 60 * 1000 + (new Date).getTime()
  localStorage.setItem('expiresDate', expireDate + '')
}

async function RefreshToken() {
  let token = localStorage.getItem('token')
  let result = await fetch(`${process.env.VUE_APP_API_URL}/api/v1/auth/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
  return await result.json().then((res) => {
    isRefreshing = false
    if (result.status === 200) {
      saveToken(res); // сохраняем полученный обновленный токен в localStorage, с помощью функции, заданной ранее
      return Promise.resolve();
    }
    return Promise.reject()
  })
}

let isRefreshing = false
let whenRefreshingList = []

export default async function FetchAbstraction(needAuth = false, method = 'POST', apiUrl = '', body = {}, headers = {}, shouldSpread = true) {
  let headersHandler = {
    headers: {
      ...headers
    }
  }
  if (needAuth) {
    let token = localStorage.getItem('token')
    if (!token) {
      await vm.$router.push({name: 'Login'})
    }
    headersHandler.headers.Authorization = `Bearer ${token}`
  }
  if (isRefreshing) {
    await new Promise((resolve, reject) => {
      whenRefreshingList.push({resolve, reject});
    })
  }
  if (method === 'GET') {
    try {
      let response = await fetch(`${process.env.VUE_APP_API_URL}${apiUrl}`, {
        method: method,
        headers: {
          ...headersHandler.headers
        },
      })
      if (response.status === 401) {
        let obj = {
          needAuth,
          method,
          apiUrl,
          body,
          headers
        }

        if (isRefreshing) return FetchAbstraction(obj.needAuth, obj.method, obj.apiUrl, obj.body, obj.headers)
        isRefreshing = true

        return await RefreshToken()
          .then(e => {
            whenRefreshingList.forEach(obj => obj.resolve())
            whenRefreshingList = []
            return FetchAbstraction(obj.needAuth, obj.method, obj.apiUrl, obj.body, obj.headers)
          })
          .catch(e => {
            let storage = window.localStorage
            whenRefreshingList.forEach(obj => obj.reject())
            whenRefreshingList = []
            storage.clear()
            vm.$router.push({name: 'Login'})
          })
      }
      return await response.json().then((res) => {
        if (shouldSpread) {
          return {
            ...res,
            ok: response.ok,
            status: response.status
          }
        } else {
          return {
            res,
            ok: response.ok,
            status: response.status
          }
        }
      })
    } catch (e) {
      throw e
    }
  }
  if (method === 'POST' || method === 'PATCH' || method === 'UPDATE') {
    if (!(body instanceof FormData)) {
      headersHandler.headers = {
        ...headersHandler.headers,
        'Content-Type': 'application/json'
      }
    }
    try {
      let response = await fetch(`${process.env.VUE_APP_API_URL}${apiUrl}`, {
        method: method,
        headers: {
          ...headersHandler.headers
        },
        body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : {}
      });
      if (response.status === 401) {
        let obj = {
          needAuth,
          method,
          apiUrl,
          body,
          headers
        }

        if (isRefreshing) return FetchAbstraction(obj.needAuth, obj.method, obj.apiUrl, obj.body, obj.headers)
        isRefreshing = true
        return await RefreshToken()
          .then(() => {
            whenRefreshingList.forEach(obj => obj.resolve())
            whenRefreshingList = []
            return FetchAbstraction(obj.needAuth, obj.method, obj.apiUrl, obj.body, obj.headers)
        })
          .catch(e => {
            let storage = window.localStorage
            whenRefreshingList.forEach(obj => obj.reject())
            whenRefreshingList = []
            storage.clear()
            vm.$router.push({name: 'Login'})
          })
      }
      return await response.json().then((res) => {
        if (shouldSpread) {
          return {
            ...res,
            ok: response.ok,
            status: response.status
          }
        } else {
          return {
            res,
            ok: response.ok,
            status: response.status
          }
        }
      })
    } catch (e) {
      throw e
    }
  }
}
