import Vue from "vue";
import VueRouter from 'vue-router'
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "Login" */ "@/containers/Login"),
    name: 'Login'
  },
  {
    path: "/register/:id",
    props: {default: true},
    component: () => import(/* webpackChunkName: "Affilate" */ "@/components/Affilate"),
    name: 'Affilate'
  },
  {
    path: "/register",
    component: () => import(/* webpackChunkName: "Register" */ "@/containers/Register"),
    name: 'Register'
  },
  {
    path: "/dashboard",
    component: () => import(/* webpackChunkName: "Dashboard" */ "@/containers/Dashboard"),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "DashboardInformation" */ "@/components/dashboard/DashboardInformation"),
        name: 'DashboardInformation'
      },
      {
        path: 'bookings',
        component: () => import(/* webpackChunkName: "Bookings" */ "@/components/dashboard/Bookings"),
        name: 'Bookings',
      },
      {
        path: 'warehouse',
        component: () => import(/* webpackChunkName: "Warehouse" */ "@/components/warehouse"),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "WarehouseStore" */ "@/components/warehouse/WarehouseStore"),
            name: 'WarehouseStore'
          },
          {
            path: 'shipped',
            component: () => import(/* webpackChunkName: "WarehouseShipped" */ "@/components/warehouse/WarehouseShipped"),
            name: 'WarehouseShipped'
          },
        ]
      },
      {
        path: 'calculator',
        component: () => import(/* webpackChunkName: "Calculator" */ "@/components/dashboard/Calculator"),
        name: 'Calculator'
      },
      {
        path: 'newBooking',
        component: () => import(/* webpackChunkName: "MakeBooking" */ "@/containers/MakeBooking"),
        name: 'NewBooking',
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "Settings" */ "@/components/dashboard/Settings"),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "AccountInformation" */ "@/components/dashboard/AccountInformation"),
            name: 'AccountInformation'
          },
          {
            path: 'personal',
            component: () => import(/* webpackChunkName: "PersonalInformation" */ "@/components/dashboard/PersonalInformation"),
            name: 'PersonalInformation'
          },
          {
            path: 'createCompany',
            component: () => import(/* webpackChunkName: "CreateCompany" */ "@/components/dashboard/CreateCompany"),
            name: 'CreateCompany'
          },
          {
            path: 'documents',
            component: () => import(/* webpackChunkName: "DocumentsInformation" */ "@/components/dashboard/DocumentsInformation"),
            name: 'DocumentsInformation'
          },
          {
            path: 'company',
            component: () => import(/* webpackChunkName: "CompanyInformation" */ "@/components/dashboard/CompanyInformation"),
            name: 'CompanyInformation'
          },
        ]
      }
    ]
  },
  {
    path: "/reset/password/:token/:email",
    component: () => import(/* webpackChunkName: "ResetPassword" */ "@/containers/ResetPassword"),
    name: 'ResetPassword'
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "@/containers/PageNotFound"),
    name: 'PageNotFound'
  },

];

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  if ((to.name === 'Login' || to.name === 'Register') && token) next({name: 'DashboardInformation'})
  if (to.name === 'DocumentsInformation' || to.name === 'CompanyInformation' || to.name === 'NewBooking') {
    const { loaded, hasCompany } = store.state.userInfo

    if (loaded && !hasCompany) next({name: 'DashboardInformation'})
    if (!loaded) store.dispatch('getUser').then(res => {
      if (!res.has_company) next({name: 'DashboardInformation'})
    })
  }
  if (to.name === 'CreateCompany') {
    const { loaded, hasCompany } = store.state.userInfo

    if (loaded && hasCompany) next({name: 'DashboardInformation'})
    if (!loaded) store.dispatch('getUser').then(res => {
      if (res.has_company) next({name: 'DashboardInformation'})
    })
  }
  if (to.name !== 'Login' && to.name !== 'ResetPassword' && to.name !== 'Affilate' && to.name !== 'Register' && !token) {
    next({name: 'Login'})
  } else {
    next()
  }
})

export default router
