import FetchAbstraction from "@/utils/FetchAbstraction";

const defaultState = {
  errorOnCreating: '',
  formStageCount: 2,
  loading: false,
  currentFormStage: 1,
  isFormCompleted: false,
  firstStep: {
    inputLoader: false,
    isUniqueErrorsList: [],
    companyName: {
      name: '',
      dictionary: 'Bedrijfsnaam',
      isFocused: false
    },
    cityName: {
      name: '',
      dictionary: 'Stad',
      isFocused: false
    },
    country: {
      name: '',
      dictionary: 'Land',
      isFocused: false
    },
    addition: {
      name: '',
      dictionary: 'Toevoeging',
      isFocused: false
    },
    houseNumber: {
      name: '',
      dictionary: 'Huisnummer',
      isFocused: false
    },
    streetHouseName: {
      name: '',
      dictionary: 'Straat',
      isFocused: false
    },
    postCode: {
      name: '',
      dictionary: 'Postcode',
      isFocused: false
    },
    btwNumber: {
      name: '',
      dictionary: 'BTW-number',
      isFocused: false
    },
    eoriNumber: {
      name: '',
      _nameForValidation: '',
      dictionary: 'EORI-nummer',
      isFocused: false,
      checked: false
    },
    kvkNumber: {
      name: '',
      dictionary: 'KVK-code',
      isFocused: false
    },
    isStepFormValid: false
  },
  secondStep: {
    // id: {
    //   file: {},
    //   errorText: [],
    //   isDirty: false
    // },
    dv: {
      file: {},
      errorText: [],
      isDirty: false,
      isFromHelloSign: false,
      track_id: null,
      helloSignIsComplite: false
    },
    kvk: {
      file: {},
      errorText: [],
      isDirty: false
    },
    isStepFormValid: false,
    isFileLoading: {
      dv: false,
      kvk: false
    },
    errorStageMessage: ''
  },
}

export default {
  state: {
    ...defaultState
  },
  mutations: {
    makeErrorOnCreatingMessage(state, msg) {
      state.errorOnCreating = msg
    },
    createCompanyChangeFormStage(state, direction) {
      state.errorOnCreating = ''
      state.secondStep.errorStageMessage = ''
      if (direction === 'back') {
        state.currentFormStage--
      }
      if (direction === 'forward') {
        state.currentFormStage++
      }
    },
    validateUniqueCodes(state, res) {
      state.firstStep.isUniqueErrorsList = []
      if (res.ok) {
          state.currentFormStage++
      } else {
        Object.keys(res).forEach(key => {
          if (key === 'eori_number' || key === 'kbo_number' || key === 'vat_number') {
            state.firstStep.isUniqueErrorsList.push(res[key])
          }
        })
      }
    },
    createCompanyBackButtonHandler(state) {
      state.currentFormStage--
    },
    doObjectEmpty(state, objName) {
        state.secondStep[objName].file = {}
        state.secondStep[objName].errorText = []
        state.secondStep[objName].isDirty = false
    },
    isFileLoading(state, { name, bool }) {
      state.secondStep.isFileLoading[name] = bool
    },
    makeFileInputsErrorText(state, {err, title}) {
      err.forEach(er => {
        state.secondStep[title].errorText.push(er)
      })
    },
    makeSecondStageErrorMessage(state, msg) {
      state.secondStep.errorStageMessage = msg
      setTimeout(() => state.secondStep.errorStageMessage = '', 10000)
    },
    putFileInState(state, {file, name}) {
      state.secondStep[name].file = file
    },
    setFromHelloSignInState(state, {flag, track_id, helloSignIsComplite, name}) {
      state.secondStep[name].isFromHelloSign = flag;
      state.secondStep[name].track_id = track_id;
      state.secondStep[name].helloSignIsComplite = helloSignIsComplite;
    },
    clearState(state) {
      state = { ...defaultState }
    },
    setLoading (state, bool = false) {
      state.loading = bool
    },
    changeEoriValidateName (state, value) {
      state.firstStep.eoriNumber._nameForValidation = value;
    },
    setEoriNumberChecked (state, { value }) {
      state.firstStep.eoriNumber.checked = value;
    }
  },
  actions: {
    async checkIsFileValid({commit, dispatch, state, rootState}, {info, title}) {
        commit('isFileLoading', { name: title, bool: true })
        commit('doObjectEmpty', title)
        if (!Object.keys(info.file).length) {
          commit('makeFileInputsErrorText', {err: ['this file is required'], title})
          commit('isFileLoading', { name: title, bool: false })
          return
        } else if (info.file.size > 5242880) {
          commit('makeFileInputsErrorText', {err: ['this file-size is not correct'], title: title})
          commit('isFileLoading', { name: title, bool: false })
          return
        }
        let type;
        switch (title) {
          // case 'id':
          //   type = 'identifier';
          //   break;
          case 'kvk':
            type = 'chamber-of-commerce';
            break;
          case 'dv':
            type = 'direct-representation';
            break;
          default:
            type = '';
            break;
        }

        const formData = new FormData()
        formData.append('document', info.file && info.file.originFileObj)
        formData.append('type', type)

        await FetchAbstraction(true, 'POST', '/api/v1/company/document/upload', formData)
          .then((res) => {
            if (!res.ok && res.document) {
              commit('makeFileInputsErrorText', {err: res.document, title})
              return
            }
            if (!res.ok && res.message) {
              commit('makeFileInputsErrorText', {err: [res.message], title})
              return
            }
            if (!res.ok) {
              commit('makeFileInputsErrorText', {err: ['something went wrong'], title})
              return
            }
            commit('putFileInState', {file: info.file, name: title})
          })
          .catch((e) => console.log(e))
          .finally(e => commit('isFileLoading', { name: title, bool: false }))

      },
    async createCompanyFirstStepValidation({commit, dispatch, state}) {
      dispatch('checkAreCodesUnique')
    },
    createCompanySecondStepValidation({commit, dispatch, state}) {
      commit('setLoading', true)
      const isDvValid = state.secondStep.dv.file.size ? state.secondStep.dv.file.size : state.secondStep.dv.isFromHelloSign
      if (state.secondStep.kvk.file.size && isDvValid) {
        let obj = {
          name: state.firstStep.companyName.name,
          vat_number: state.firstStep.btwNumber.name,
          eori_number: state.firstStep.eoriNumber.name,
          kbo_number: state.firstStep.kvkNumber.name,
          address: {
            place: state.firstStep.cityName.name,
            street: state.firstStep.streetHouseName.name,
            postcode: state.firstStep.postCode.name,
            house_number: state.firstStep.houseNumber.name,
            country: state.firstStep.country.name,
          }
        }
        if (state.firstStep.addition.name) {
          obj.address.addition = state.firstStep.addition.name
        }
        if (state.secondStep.dv.track_id) {
          obj.track_id = state.secondStep.dv.track_id
        }
        return FetchAbstraction(true, 'POST', '/api/v1/company', obj)
          .then((res) => {
            if (!res.ok) {
              commit('makeErrorOnCreatingMessage', res.message)
            } else if (res.status === 409) {
              commit('makeErrorOnCreatingMessage', res.message);
              if (res.track_status === 'declined' || res.track_status === 'canceled') {
                state.dv.helloSignIsComplite = false;
              }
            } else {
              return res
            }
          })
          .catch((e) => commit('makeErrorOnCreatingMessage', e.message))
          .finally(() => commit('setLoading'))
      } else {
        commit('setLoading')
        commit('makeSecondStageErrorMessage', 'Please, upload all files')
      }
    },
    async checkAreCodesUnique({commit, dispatch, state}) {
      commit('setLoading', true)
      let userCode = {
        vat_number: state.firstStep.btwNumber.name,
        eori_number: state.firstStep.eoriNumber.name,
        kbo_number: state.firstStep.kvkNumber.name
      }

      return await FetchAbstraction(false, 'POST', '/api/v1/validation/company', userCode)
        .then((res) => {
          commit('validateUniqueCodes', res)
        })
        .catch((e) => {
          commit('makeErrorOnCreatingMessage', 'Something went wrong')
        })
        .finally(() => commit('setLoading'))
    },
    createCompanyBackButtonHandler({commit}, payload) {
      commit('changeFormStage', 'back')
    },
  }
}
