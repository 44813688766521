<template>
  <div>
    <div class="thirdStepContainer">
      <div class="col">
        <h3 class="col__title">
          Kamer van Koophandel uittreksel
        </h3>
        <span class="col__text">
              We hebben een gewaarmerkt Kamer van Koophandel uittreksel nodig.
              <a
                href="https://www.kvk.nl/orderstraat/bedrijf-kiezen/?prefproduct=Digitaal+gewaarmerkt+uittreksel#!shop?&q=&prefproduct=Digitaal+gewaarmerkt+uittreksel"
                target="_blank"
              >
                Klik hier om het document te tekenen
              </a>
              van de Kamer van Koophandel.
            </span>
        <div class="col__upload">
          <a-upload-dragger
            name="kvk"
            :multiple="false"
            :customRequest="e => getFile(e, 'kvk')"
            @change="e => handleChangeFile(e, 'kvk')"
            :disabled="thirdStep.loadingFile"
            :fileList="fileList.kvk"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox"/>
            </p>
            <p class="ant-upload-text">
              Klik of sleep het bestand naar dit gebied om het te uploaden
            </p>
          </a-upload-dragger>
          <a-badge :status="thirdStep.kvk.errorText.length ? 'error' : 'success'"
                   v-show="thirdStep.kvk.isDirty"
                   :text="thirdStep.kvk.errorText.length ? 'error' : 'Goedgekeurd'"
          />
          <a-list size="small" bordered v-show="thirdStep.kvk.errorText.length" style="margin-top: 15px">
            <a-list-item v-for="(error, idx) in thirdStep.kvk.errorText" :key="idx + error">
              <a-badge :status="'error'"
                       :text="error"
              />
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div class="col">
        <h3 class="col__title">
          <a-tooltip>
            <template slot="title">
              Je dient een paraaf neer te zetten op elke bladzijde bij ‘Opdrachtgever’! Ook dien je op pagina 1 en 4
              bedrijfs- en persoonsgegevens in te vullen bij de kopjes ‘Opdrachtgever / direct vertegenwoordigde’. Zorg
              ervoor dat alle info juist is ingevuld, anders kan het document niet juist verwerkt worden en kunnen wij
              geen producten voor jou inklaren.
            </template>
            Directe Vertegenwoordiging document
          </a-tooltip>
        </h3>
        <span class="col__text">
          Om goederen te mogen inklaren hebben we een Directe Vertegenwoordiging nodig.
          <a
            :href="dvDownloadUrl"
          >
            Klik hier om het document te tekenen
          </a>
          en upload deze daarna hier.
        </span>
        <div class="col__upload">
          <a-upload-dragger
            name="dv"
            :multiple="false"
            :customRequest="e => getFile(e, 'dv')"
            @change="e => handleChangeFile(e, 'dv')"
            :disabled="thirdStep.loadingFile"
            :fileList="fileList.dv"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox"/>
            </p>
            <p class="ant-upload-text">
              Klik of sleep het bestand naar dit gebied om het te uploaden
            </p>
          </a-upload-dragger>
          <a-badge :status="thirdStep.dv.errorText.length ? 'error' : 'success'"
                   v-show="thirdStep.dv.isDirty"
                   :text="thirdStep.dv.errorText.length ? 'error' : 'Goedgekeurd'"
          />
          <a-list size="small" bordered v-show="thirdStep.dv.errorText.length" style="margin-top: 15px">
            <a-list-item v-for="(error, idx) in thirdStep.dv.errorText" :key="error + idx">
              <a-badge :status="'error'"
                       :text="error"
              />
            </a-list-item>
          </a-list>
        </div>
      </div>
      <!--      <div class="col">-->
      <!--        <h3 class="col__title">-->
      <!--          Kamer nav Koophandel uittreksel-->
      <!--        </h3>-->
      <!--        <span class="col__text">-->
      <!--        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam earum explicabo hic-->
      <!--        natus neque nulla sapiente sint suscipit. Distinctio ea-->
      <!--        <router-link to="/">explicabo nemo non quibusdam</router-link> quod soluta! Laboriosam quasi reprehenderit similique!-->
      <!--      </span>-->
      <!--        <div class="col__upload">-->
      <!--          <a-upload-dragger-->
      <!--            name="id"-->
      <!--            :multiple="false"-->
      <!--            :customRequest="e => getFile(e, 'id')"-->
      <!--            @change="e => handleChangeFile(e, 'id')"-->
      <!--            :disabled="thirdStep.loadingFile"-->
      <!--            :fileList="fileList.id"-->
      <!--          >-->
      <!--            <p class="ant-upload-drag-icon">-->
      <!--              <a-icon type="inbox"/>-->
      <!--            </p>-->
      <!--            <p class="ant-upload-text">-->
      <!--              Click or drag file to this area to upload-->
      <!--            </p>-->
      <!--          </a-upload-dragger>-->
      <!--          <a-badge :status="thirdStep.id.errorText.length ? 'error' : 'success'"-->
      <!--                   v-show="thirdStep.id.isDirty"-->
      <!--                   :text="thirdStep.id.errorText.length ? 'error' : 'success'"-->
      <!--          />-->
      <!--          <a-list size="small" bordered v-show="thirdStep.id.errorText.length" style="margin-top: 15px">-->
      <!--            <a-list-item v-for="error in thirdStep.id.errorText">-->
      <!--              <a-badge :status="'error'"-->
      <!--                       :text="error"-->
      <!--              />-->
      <!--            </a-list-item>-->
      <!--          </a-list>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <a-alert
      :message="error"
      type="error"
      close-text="Close Now"
      v-if="error.length"
    />
    <a-alert
      :message="errorOnCreating"
      type="error"
      close-text="Close Now"
      v-if="errorOnCreating.length"
    />
    <div>
      <a-spin v-if="thirdStep.isFileLoading">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin/>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'RegistrationThirdStep',
  props: {
    shouldCheckValidate: Boolean
  },
  beforeDestroy() {
    Object.keys(this.thirdStep).forEach(obj => {
      if (this.thirdStep[obj] && this.thirdStep[obj].isDirty) {
        this.doObjectEmpty(obj)
      }
    })
  },
  data() {
    return {
      onSuccess: false,
      error: '',
      fileList: {
        kvk: [],
        dv: [],
        // id: []
      }
    }
  },
  computed: {
    ...mapState({
      thirdStep: state => state.register.thirdStep,
      errorOnCreating: state => state.register.errorOnCreating,
    }),
    dvDownloadUrl() {
      return `${process.env.VUE_APP_API_URL}/api/v1/dv/download`
    }
  },
  watch: {
    shouldCheckValidate(val) {
      if (val === true) {
        this.checkValidate()
      }
    }
  },
  methods: {
    ...mapActions([
      'checkIsFileValid',
    ]),
    ...mapMutations([
      'doObjectEmpty',
    ]),
    getFile({onSuccess}) {
      setTimeout(() => {
        this.onSuccess = true;
        onSuccess("ok");
      }, 0)
    },
    handleChangeFile(info, title) {
      this.fileList[title] = info.fileList;
      if (info.fileList.length >= 2) {
        this.fileList[title] = info.fileList.filter((file, idx, arr) => {
          return idx === arr.length - 1
        })
      }
      if (this.onSuccess && info.fileList.length) {
        this.checkIsFileValid({info, title});
        this.thirdStep[title].isDirty = true;
        this.onSuccess = false
      }
      if (!info.fileList.length) {
        this.thirdStep[title].isDirty = false;
        this.doObjectEmpty(title)
      }
    },
    checkValidate() {
      let res = false;
      let conditionHasFile = this.thirdStep.dv.file.size
        && this.thirdStep.kvk.file.size
      // && this.thirdStep.id.file.size;
      let conditionHasError =
        !this.thirdStep.dv.errorText.length
        && !this.thirdStep.kvk.errorText.length
      // && !this.thirdStep.id.errorText.length;


      if (conditionHasFile && conditionHasError) {
        res = true
      }
      if (!res) {
        this.error = 'all field must be filled and must be correct';
        setTimeout(() => {
          this.error = ''
        }, 5000);
        this.$emit('validationFailed');
      } else {
        this.thirdStep.isStepFormValid = true;
        this.$emit('validationSuccess')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.thirdStepContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.col {
  width: 47%;
}

.inputFileWrapper {
  max-height: 120px;
}

.col__upload {
  margin-top: 20px;

}

</style>
