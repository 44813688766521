<template>
  <div class="benefits">
    <div class="benefits__logo">
      <img :src="logo" alt="">
    </div>
    <h2 class="benefits__title">
      Import4You biedt jou de beste logistieke oplossing voor jouw onderneming!
    </h2>
    <div class="benefits__listWrapper">
      <ul class="benefits__list">
        <li class="benefits__list--item">
          Jarenlange ervaring voor zekerheid dat je producten in goede handen zijn
        </li>
        <li class="benefits__list--item">
          Ingewikkelde administratieve processen worden voor je geregeld
        </li>
        <li class="benefits__list--item">
          Volledige focus op je eigen onderneming
        </li>
        <li class="benefits__list--item">
          De beste service, de scherpste tarieven en de snelste levertijd
        </li>
        <li class="benefits__list--item">
          Verzending met honderden ondernemers als collectief, voor een lager kilotarief
        </li>
        <li class="benefits__list--item">
          Ruim 40.000 m2 aan opslagcapaciteit
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import logo from '@/assets/Logo-horizontaal.png'

  export default {
    name: 'RegistrationBenefits',
    data() {
      return {
        logo
      }
    }
  }
</script>

<style lang="scss" scoped>
  .benefits {
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    width: 40%;
    max-width: 350px;
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-self: flex-end;
    max-height: 770px;
  }

  .benefits__logo {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 50px;

    img {
      width: 100%;
    }
  }

  .benefits__title {
    font-weight: bold;
    max-width: 300px;
  }

  .benefits__listWrapper {
    max-width: 300px;
  }

  .benefits__list {
    padding: 0;
    list-style: none;
    margin-top: 30px;

    &--item {
      padding-bottom: 20px;
      font-size: 15px;
      display: flex;

      &::before {
        content: " \2713";
        color: #FFA300;
        display: block;
        width: 7px;
        height: 7px;
        margin-right: 10px;

      }
    }
  }
</style>
