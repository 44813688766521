import { notification } from 'ant-design-vue'

export function makeNotification (props) {
  const { type = 'success', message = 'messgae', description = 'description', duration = 0, key = Math.random() } = props
  notification[type]({
    message,
    description,
    duration,
    key
  })
}
export function closeNotification ({ key = '' }) {
  notification.close(key)
}
