export default {
    methods: {
        focusHandler(el, toFocus, step) {
            if (el === 'password' || el === 'repeatPassword') {
                if (!toFocus) {
                    this.$v[step][el].$touch()
                }
                if (toFocus || this[step][el].name.length) {
                    this[step][el].isFocused = true
                    return;
                }
                this[step][el].isFocused = false
            } else if (el === 'sixCode') {
                if (toFocus || this[step][el].name.length) {
                    this[step][el].isFocused = true
                    return;
                }
                this[step][el].isFocused = false
            } else {
                if (!toFocus) {
                    this.$v[step][el].$touch()
                }
                if (toFocus || this[step][el].name.length) {
                    this[step][el].isFocused = true
                    return;
                }
                this[step][el].isFocused = false
            }
        },
    }
}
