import { closeNotification, makeNotification } from "../utils/notificationHandler";
import { SHIPPING_ERRORS_KEY, PERSONAL_REF_ERROR_KEY, DELETE_ADDRESS_TEMPLATE_KEY, EDIT_ADDRESS_TEMPLATE_KEY } from "../components/warehouse/notificationConstants";
import FetchAbstraction from "../utils/FetchAbstraction";
import laravelErrorHandler from "../utils/laravelErrorHandler";

const defaultProducts = [
  {
    countOfBoxes: '',
    personalReference: '',
    personalReferenceFromBackend: '',
    isPersonalRefLoading: false,
    checked: false,
    available: true,
    maxBoxesCount: 10,
    id: 1,
    options: {
      product_description: {
        value: 'description of product',
        dictionary: 'Description'
      },
      count_of_pallets: {
        value: '2344',
        dictionary: 'Pallets count'
      },
      boxes_count: {
        value: '2344',
        dictionary: 'Boxes count'
      }
    }
  },
  {
    countOfBoxes: '',
    personalReference: '',
    personalReferenceFromBackend: '',
    isPersonalRefLoading: false,
    checked: false,
    available: true,
    maxBoxesCount: 10,
    id: 2,
    options: {
      product_description: {
        value: 'description of product',
        dictionary: 'Description'
      },
      count_of_pallets: {
        value: '2344',
        dictionary: 'Pallets count'
      },
      boxes_count: {
        value: '2344',
        dictionary: 'Boxes count'
      }
    }
  },
  {
    countOfBoxes: '',
    personalReference: '',
    personalReferenceFromBackend: '',
    isPersonalRefLoading: false,
    checked: false,
    available: true,
    maxBoxesCount: 10,
    id: 3,
    options: {
      product_description: {
        value: 'description of product',
        dictionary: 'Description'
      },
      count_of_pallets: {
        value: '2344',
        dictionary: 'Pallets count'
      },
      boxes_count: {
        value: '2344',
        dictionary: 'Boxes count'
      }
    }
  },
]
const defaultFormFields = {
  lvb: {
    id: 1,
    renderingCondition: [],
    name: null,
    dictionary: 'Gaat het om een LVB-levering?',
    checkoutDictionary: 'LVB',
    isFocused: false,
    inputSettings: {
      type: 'Select',
      options: [
        {
          value: 1,
          label: 'Ja'
        },
        {
          value: 0,
          label: 'Nee'
        }
      ]
    }
  },
  lvbOptions: {
    id: 2,
    renderingCondition: ['lvb:1'],
    name: '',
    dictionary: 'LVB-gegevens',
    isFocused: false,
    inputSettings: {
      type: 'Select',
      options: []
    }
  },
  street: {
    id: 5,
    renderingCondition: ['lvb:0'],
    name: '',
    dictionary: 'Straat',
    isFocused: false,
    inputSettings: {
      type: 'Auto-complete-select',
      options: []
    },
  },
  houseNumber: {
    id: 6,
    renderingCondition: ['lvb:0'],
    name: '',
    dictionary: 'Huisnummer',
    isFocused: false,
    inputSettings: {
      type: 'Input'
    }
  },
  addition: {
    id: 7,
    renderingCondition: ['lvb:0'],
    name: '',
    dictionary: 'Toevoeging',
    isFocused: false,
    inputSettings: {
      type: 'Input'
    }
  },
  zipCode: {
    id: 8,
    renderingCondition: ['lvb:0'],
    name: '',
    dictionary: 'Postcode',
    isFocused: false,
    inputSettings: {
      type: 'Input'
    }
  },
  city: {
    id: 9,
    renderingCondition: ['lvb:0'],
    name: '',
    dictionary: 'Stad',
    isFocused: false,
    inputSettings: {
      type: 'Input'
    }
  },
  country: {
    id: 10,
    renderingCondition: [],
    name: null,
    dictionary: 'Land',
    isFocused: false,
    inputSettings: {
      type: 'Select',
      options: [
        {
          value: 1,
          label: 'Nederland'
        },
        {
          value: 2,
          label: 'België'
        }
      ]
    }
  }
}
const defaultCheckoutFormFields = {
  personalRef: {
    id: 1,
    renderingCondition: [],
    name: '',
    dictionary: 'Persoonlijke referentie',
    isFocused: false,
    inputSettings: {
      type: 'Input'
    }
  }
}
const defaultFiles =  {
  delivery: {
        uploadText: 'Upload LVB Verzendlabel',
        title: 'LVB Verzendlabel',
        // description: 'Description of first file Description of first file Description of first file Description of first file Description of first file',
        errorText: [],
        file: {},
        loading: false,
        isDirty: false
  },
  packing: {
        uploadText: 'Upload LVB Paklijst',
        title: 'LVB Paklijst',
        // description: 'Description of second file Description of second file Description of second file Description of second file Description of second file',
        errorText: [],
        file: {},
        loading: false,
        isDirty: false
  }
}
const defaultMultiUploadFiles = () => ({
  title: 'Upload documenten',
  uploadText: 'Upload jouw documenten hier of klik om te zoeken',
  toolTipText: 'Als er documenten vanuit je fulfillment partij o.i.d op de dozen geplakt dienen te worden, kan je ze hier uploaden.',
  errorText: [],
  file: [],
  isDirty: false,
  isFileLoading: false,
  isFileRemoving: false,
});
const mapKeys = {
  addition: 'addition',
  city: 'city',
  house_number: 'houseNumber',
  street: 'street',
  post_code: 'zipCode',
  country_index: 'country'
}
const INITIAL_PAGINATION = () => ({
  totalItems: null,
  itemsPerPage: null,
  currentPage: null,
});
const DELIVERY_TEMPLATE_DATA = {
  street: 'street',
  house_number: 'houseNumber',
  addition: 'addition',
  country_index: 'country',
  city: 'city',
  post_code: 'zipCode',
};
export default {
  namespaced: true,
  state: {
    currentStep: 1,
    isFormFilling: false,
    isProductsLoading: false,
    isShippingLoading: false,
    isSuccessfullyPassed: false,
    isShippedProductsLoading: false,
    shippingErrors: [],
    deleteAddressTemplatesError: [],
    products: [],
    formFields: {
      lvb: {
        id: 1,
        renderingCondition: [],
        name: null,
        dictionary: 'Gaat het om een LVB-levering?',
        checkoutDictionary: 'LVB',
        isFocused: false,
        inputSettings: {
          type: 'Select',
          options: [
            {
              value: 1,
              label: 'Ja'
            },
            {
              value: 0,
              label: 'Nee'
            }
          ]
        }
      },
      lvbOptions: {
        id: 2,
        renderingCondition: ['lvb:1'],
        name: '',
        dictionary: 'LVB-gegevens',
        isFocused: false,
        inputSettings: {
          type: 'Select',
          options: []
        }
      },
      street: {
        id: 5,
        renderingCondition: ['lvb:0'],
        name: '',
        dictionary: 'Straat',
        isFocused: false,
        inputSettings: {
          type: 'Auto-complete-select',
          options: []
        },
      },
      houseNumber: {
        id: 6,
        renderingCondition: ['lvb:0'],
        name: '',
        dictionary: 'Huisnummer',
        isFocused: false,
        inputSettings: {
          type: 'Input'
        }
      },
      addition: {
        id: 7,
        renderingCondition: ['lvb:0'],
        name: '',
        dictionary: 'Toevoeging',
        isFocused: false,
        inputSettings: {
          type: 'Input'
        }
      },
      zipCode: {
        id: 8,
        renderingCondition: ['lvb:0'],
        name: '',
        dictionary: 'Postcode',
        isFocused: false,
        inputSettings: {
          type: 'Input'
        }
      },
      city: {
        id: 9,
        renderingCondition: ['lvb:0'],
        name: '',
        dictionary: 'Stad',
        isFocused: false,
        inputSettings: {
          type: 'Input'
        }
      },
      country: {
        id: 10,
        renderingCondition: [],
        name: null,
        dictionary: 'Land',
        isFocused: false,
        inputSettings: {
          type: 'Select',
          options: [
            {
              value: 1,
              label: 'Nederland'
            },
            {
              value: 2,
              label: 'België'
            }
          ]
        }
      }
    },
    templateData: {
      street: {
        id: 1,
        name: '',
        renderingCondition: [],
        isFocused: false,
        dictionary: 'Straat',
        inputSettings: {
          type: 'Input'
        }
      },
      houseNumber: {
        id: 2,
        name: '',
        renderingCondition: [],
        isFocused: false,
        dictionary: 'Huisnummer',
        inputSettings: {
          type: 'Input'
        }
      },
      addition: {
        id: 3,
        name: '',
        renderingCondition: [],
        isFocused: false,
        dictionary: 'Toevoeging',
        inputSettings: {
          type: 'Input'
        }
      },
      zipCode: {
        id: 4,
        name: '',
        renderingCondition: [],
        isFocused: false,
        dictionary: 'Postcode',
        inputSettings: {
          type: 'Input'
        }
      },
      city: {
        id: 5,
        name: '',
        renderingCondition: [],
        isFocused: false,
        dictionary: 'Stad',
        inputSettings: {
          type: 'Input'
        }
      },
      country: {
        id: 6,
        name: '',
        renderingCondition: [],
        isFocused: false,
        dictionary: 'Land',
        inputSettings: {
          type: 'Select',
          options: [
            {
              value: '1',
              label: 'Nederland'
            },
            {
              value: '2',
              label: 'België'
            }
          ]
        }
      },
    },
    checkoutFormFields: {
      personalRef: {
        id: 1,
        renderingCondition: [],
        name: '',
        dictionary: 'Persoonlijke referentie',
        isFocused: false,
        inputSettings: {
          type: 'Input'
        }
      }
    },
    dateField: {
      label: 'Selecteer wanneer u uw dozen wilt laten bezorgen:',
      name: null
    },
    files: {
      delivery: {
        uploadText: 'Upload LVB Verzendlabel',
        title: 'LVB Verzendlabel',
        // description: 'Description of first file Description of first file Description of first file Description of first file Description of first file',
        errorText: [],
        file: {},
        loading: false,
        isDirty: false
      },
      packing: {
        uploadText: 'Upload LVB Paklijst',
        title: 'LVB Paklijst',
        // description: 'Description of second file Description of second file Description of second file Description of second file Description of second file',
        errorText: [],
        file: {},
        loading: false,
        isDirty: false
      }
    },
    multiUploadFiles: defaultMultiUploadFiles(),
    shippedProducts: [],
    saveDeliveryLayout: {
      label: "Adres opslaan",
      checked: false
    },
    pagination: INITIAL_PAGINATION(),
    isChangingTemplateData: false,
    isLoadingTemplate: false,
  },
  mutations: {
    COUNT_OF_BOXES_HANDLER (state, { index, num }) {
      state.products.forEach((product, idx) => {
        if (idx === index) {
          product.countOfBoxes = typeof num === 'number' ? num : ''
          product.checked = !!num
        }
      })
    },
    COUNT_OF_SUBPRODUCTS_BOXES_HANDLER (state, { productIdx, subProdIdx, inputValue }) {
      state.products[productIdx].product_subs.forEach((subProduct, idx) => {
        if (idx === subProdIdx) {
          subProduct.countOfSubProductBoxes = typeof inputValue === 'number' ? inputValue : '';
          subProduct.checked = !!inputValue;
        }
      });

      const data = state.products[productIdx].product_subs.reduce((accum, { countOfSubProductBoxes }) => {
        return accum + +countOfSubProductBoxes || 0;
      }, 0)

      state.products[productIdx].countOfBoxes = data || '';
      state.products[productIdx].checked = !!data;
    },
    RESET_COUNT_OF_BOXES (state) {
      state.products.forEach(product => {
          product.countOfBoxes =  ''; 
          product.checked = false;      
          product.product_subs && product.product_subs.length && product.product_subs.forEach((subProduct) => {
            subProduct.countOfSubProductBoxes = "";
            subProduct.checked = false;
          })
        })
    },
    SET_LOADING_PERSONAL_REF (state, { bool, productId }) {
      (state.products.find(product => product.id === productId) || {}).isPersonalRefLoading = bool
    },
    SET_PERSONAL_REF_FEEDBACK (state, { error, success }) {
      if (!error && !success) {
        closeNotification({ key: PERSONAL_REF_ERROR_KEY } )
      } else {
        let errorMessage = "Oops!"

        if (typeof error === 'object') {
          if (error.message) errorMessage = error.message
        }

        makeNotification({
          key: PERSONAL_REF_ERROR_KEY,
          message: success ? 'Succes!' : errorMessage,
          type: success ? 'success' : 'error',
          description: success ? 'Jouw persoonlijke referentie is succesvol aangepast' : function (h) {
            return laravelErrorHandler(h, error)
          },
          duration: 2,
        })
      }
    },
    SET_NEW_PERSONAL_REF (state, { productId, value }) {
      const currPersonalRef = state.products.find(product => product.id === productId) || {}
      currPersonalRef.personalReference = value
      currPersonalRef.personalReferenceFromBackend = value
    },
    SET_SHIPPING_ERRORS (state, { error }) {
      state.shippingErrors.push(error)
      makeNotification({
        key: SHIPPING_ERRORS_KEY,
        message: 'Oops!',
        type: 'error',
        description: error
      })
    },
    SET_SAVE_DELIVERY_TEMPLATE_ERRORS (state, { error, success, key }) {
      state.deleteAddressTemplatesError.push(error)
      makeNotification({
        key,
        message: error ? 'Fout!' : "Succes!",
        type: error ? 'error' : 'success',
        description: error ? error : success,
        duration: 5
      })
    },
    SET_PRODUCTS(state, { products }) {
      state.products = products.map(product => {
        return {
          countOfBoxes: '',
          personalReference: product.personal_ref || '',
          personalReferenceFromBackend: product.personal_ref || '',
          isPersonalRefLoading: false,
          checked: false,
          available: true,
          maxBoxesCount: product.boxes_count,
          id: product.id,
          options: {
            product_description: {
              value: product.product.description,
              dictionary: 'Beschrijving'
            },
            boxes_per_pallet: {
              value: product.boxes_per_pallet,
              dictionary: 'Boxes per pallet'
            },
            count_of_pallets: {
              value: product.count_of_pallets,
              dictionary: 'Aantal pallets'
            },
            boxes_count: {
              value: product.boxes_count,
              dictionary: 'Aantal dozen in opslag'
            }
          },
          status: product.status,
          booking_uuid: product.product.booking_uuid,
          product_subs: product.product.warehouse_product.warehouse_product_subs.map(subProduct => {
            return {
              id: subProduct.product_sub_id,
              description: subProduct.product_sub.description,
              countOfSubProductBoxes: '',
              checked: false,
              warehouseCountOfBoxes: subProduct.warehouse_boxes,
              boxesPerPallet: subProduct.boxes_per_pallet,
            }
          }),
          showSubProducts: false
        }
      })
    },
    SET_SHIPPED_PRODUCTS (state, { products }) {
      state.shippedProducts = products.map(product => {
        return {
          show: false,
          id: product.id,
          status: {
            name: product.status_details.name,
            id: product.status_details.id
          },
          header: [
            {
              name: 'uuid',
              value: product.uuid
            },
            {
              name: 'personalReference',
              value: product.personal_ref
            },
            {
              name: 'createdAt',
              value: product.created_at.split('T')[0]
            },
            {
              name: 'deliveryDate',
              value: product.shipment_delivery.delivery_date
            },
            {
              name: 'status',
              value: product.status_details.name
            },
          ],
          shipmentDelivery: {
            contact: {
              dictionary: 'Contactpersoon',
              value: product.shipment_delivery.contact,
            },
            companyName: {
              dictionary: 'Bedrijfsnaam',
              value: product.shipment_delivery.company_name,
            },
            lvb: {
              dictionary: 'LVB',
              value: product.shipment_delivery.lvb,
            },
            street: {
              dictionary: 'Straat',
              value: product.shipment_delivery.shipment_delivery_address.street,
            },
            place: {
              dictionary: 'Stad',
              value: product.shipment_delivery.shipment_delivery_address.place,
            },
            postcode: {
              dictionary: 'Postcode',
              value: product.shipment_delivery.shipment_delivery_address.postcode,
            },
            houseNumber: {
              dictionary: 'Huisnummer',
              value: product.shipment_delivery.shipment_delivery_address.house_number,
            },
            country: {
              dictionary: 'Land',
              value: product.shipment_delivery.shipment_delivery_address.country,
            },
            addition: {
              dictionary: 'Toevoeging',
              value: product.shipment_delivery.shipment_delivery_address.addition,
            }
          },
          shipmentProducts: product.warehouse_products.map(item => {
            return {
              personalRef: {
                dictionary: 'Persoonlijke referentie',
                value: item.personal_ref
              },
              remainderBoxesCount: {
                dictionary: 'Overgebleven dozen',
                value: item.boxes_count,
              },
              countOfPallets: {
                dictionary: 'Aantal pallets',
                value: item.count_of_pallets,
              },
              boxesCount: {
                dictionary: 'Hoeveelheid dozen',
                value: item.pivot.boxes_count
              }
            }
          })
        }
      })
    },
    PRODUCTS_LOADING_HANDLER (state, { bool }) {
      state.isProductsLoading = bool
    },
    SHIPPING_LOADING_HANDLER (state, { bool }) {
      state.isShippingLoading = bool
    },
    FILES_LOADING_HANDLER (state, { fileName, bool }) {
      state.files[fileName].loading = bool
    },
    SHIPPED_PRODUCTS_HANDLER (state, { bool }) {
      state.isShippedProductsLoading = bool
    },
    CHANGE_PERSONAL_REF_TO_BACK_VARIANT (state, { productId }) {
      const currPersonalRef = state.products.find(product => product.id === productId) || {}
      currPersonalRef.personalReference = currPersonalRef.personalReferenceFromBackend
    },
    SAVE_DATE_INPUT_VALUE (state, { value }) {
      state.dateField.name = value
    },
    PUT_LVB_LOCATIONS_LIST (state, { locations }) {
      state.formFields.lvbOptions.inputSettings.options = [...locations]
    },
    PUT_FILE_TO_STATE (state, { file, name }) {
      state.files[name].file = file
    },
    MAKE_FILE_EMPTY (state, { title }) {
      state.files[title].errorText = []
      state.files[title].isDirty = false
    },
    MAKE_FILE_IS_DIRTY (state, { title }) {
      state.files[title].isDirty = true
    },
    MAKE_FILE_ERRORS (state, { err, title }) {
      err.forEach(er => {
        state.files[title].errorText.push(er)
      })
    },
    DELETE_FILE_FROM_STORE (state, { title }) {
      state.files[title].file = {}
    },
    START_FILLING_FORM (state) {
      state.isFormFilling = true
    },
    END_FILLING_FORM (state) {
      state.isFormFilling = false
    },
    STEP_BACK (state) {
      state.currentStep--
    },
    STEP_FRONT (state) {
      state.currentStep++
    },
    SHIPPING_SUCCESSFULLY_FINISHED (state) {
      state.currentStep = 1
      state.isFormFilling = false
      state.isSuccessfullyPassed = true
      state.dateField.name = null
      state.formFields = JSON.parse(JSON.stringify(defaultFormFields))
      state.files = JSON.parse(JSON.stringify(defaultFiles))
      state.multiUploadFiles = JSON.parse(JSON.stringify(defaultMultiUploadFiles()))
      state.products = []
      state.checkoutFormFields = JSON.parse(JSON.stringify(defaultCheckoutFormFields))
      state.saveDeliveryLayout.checked = false
    },
    CLEAR_SHIPPING_ERRORS (state) {
      state.shippingErrors = []
      closeNotification({ key: SHIPPING_ERRORS_KEY })
    },
    CLEAR_SAVE_DELIVERY_TEMPLATE_ERRORS (state) {
      state.deleteAddressTemplatesError = []
      closeNotification({ key: DELETE_ADDRESS_TEMPLATE_KEY });
      closeNotification({ key: EDIT_ADDRESS_TEMPLATE_KEY });
    },
    RESTART_WAREHOUSE (state) {
      state.isSuccessfullyPassed = false
    },
    PUT_DELIVERY_INFO (state, { obj }) {
      obj.country_index = obj.country_index === '1' ? 'Nederland' : 'België';
      
      Object.keys(obj).forEach(key => {
        if (state.formFields[mapKeys[key]]) {
          state.formFields[mapKeys[key]].name = obj[key];
          state.formFields[mapKeys[key]].isFocused = !!obj[key]
        }
      })
    },
    MAKE_DELIVERY_LIST (state, { list }) {
      state.formFields.street.inputSettings.options = list.map(item => ({ label: item.street, value: item.id }))
    },
    SAVE_DELIVERY_DATA_HANDLER (state, { bool }) {
      state.saveDeliveryLayout.checked = bool      
    },
    UPDATE_PAGINATION (state, data) {
      data ? (state.pagination = data) : state.pagination = INITIAL_PAGINATION();
    },
    EDIT_TEMPLATE(state, data) {
      Object.keys(DELIVERY_TEMPLATE_DATA).forEach(item => {
        state.templateData[DELIVERY_TEMPLATE_DATA[item]].name = data[item];
        state.templateData[DELIVERY_TEMPLATE_DATA[item]].isFocused = !!data[item];
      })
    },
    CHANGE_LOADING_STATUS(state, { field, value }) {
      state[field] = value;
    },
    CLEAR_AUTOFILLED_DATA(state, { step, fields }) {
      fields.forEach(field => {
        state[step][field].name = '';
        state[step][field].isFocused = false;
      })
    },
    CLEAR_MULTI_UPLOAD_FILES_ERROS(state) {
      state.multiUploadFiles.errorText = [];
    },
    ADD_MULTI_UPLOAD_ERROR_TEXT(state, { err }) {
      state.multiUploadFiles.errorText = []
      err.forEach(er => {
        state.multiUploadFiles.errorText.push(er)
      })
      setTimeout(() => state.multiUploadFiles.errorText = [], 5000)
    },
    CHANGE_FILE_UPLOADING_STATUS(state, { field, value }) {
      state[field].isFileLoading = value;
    },
    CHANGE_FILE_REMOVING_STATUS(state, { field, value }) {
      state[field].isFileRemoving = value;
    },
    ADD_MULTI_LOAD_FILES(state, { file, id }) {
      state.multiUploadFiles.file.push({ file, id });
    },
    REMOVE_MULTI_LOAD_FILE(state, { id }) {
      state.multiUploadFiles.file = state.multiUploadFiles.file.filter(document => document.id !== id);
    },
    CLEAR_MULTI_LOAD_FILE_STATE(state) {
      state.multiUploadFiles.file = []
      state.multiUploadFiles.errorText = []
      state.multiUploadFiles.isDirty = false
      state.multiUploadFiles.loadingFile = false
    },
  },
  actions: {
    async lvbLocations ({ commit, state, rootState }) {
      if (
        !rootState.dashboardMain.mkThree.lvbNumeric.dataSource.length &&
        !state.formFields.lvbOptions.inputSettings.options.length
      ) {
        await FetchAbstraction(true, 'GET', '/api/v1/lvb-location/list')
          .then((res) => {
            if (res.ok) {
              let locations = []
              Object.keys(res).forEach(key => {
                if (res[key].id) {
                  locations.push(res[key])
                }
              })
              locations = locations.map(location => {
                let label = `
                  Stad: ${ location.city };
                  Land: ${ location.country };
                  Postcode: ${ location.postcode };
                  Straat: ${ location.street };
                  Huisnummer: ${ location.house_number }
                `
                return {
                  label,
                  value: JSON.stringify(location)
                }
              })
              commit('PUT_LVB_LOCATIONS_LIST', { locations })
            }
          })
          .catch((e) => console.log(e))
      }
    },
    async checkDocument ({ commit, dispatch, state, rootState }, { info, title }) {
      commit('FILES_LOADING_HANDLER', { fileName: title, bool: true })
      commit('MAKE_FILE_EMPTY', { title })
      if (!Object.keys(info.file).length) {
        commit('MAKE_FILE_ERRORS', { err: ['this file is required'], title })
        commit('FILES_LOADING_HANDLER', { fileName: title, bool: false })
        return
      } else if (info.file.size > 5242880) {
        commit('MAKE_FILE_ERRORS', { err: ['this file-size is not correct'], title: title })
        commit('FILES_LOADING_HANDLER', { fileName: title, bool: false })
        return
      }
      let type;
      switch (title) {
        case 'delivery':
          type = 'lvb_delivery_list';
          break;
        case 'packing':
          type = 'lvb_packing_list';
          break;
        default:
          type = '';
          break;
      }

      const formData = new FormData()
      formData.append('document', info.file && info.file.originFileObj)
      formData.append('type', type)

      await FetchAbstraction(true, 'POST', `/api/v1/shipment/document/${ title === 'delivery' ? 'upload-delivery' : 'upload-packing' }`, formData)
        .then((res) => {
          if (!res.ok && res.document) {
            commit('MAKE_FILE_ERRORS', { err: res.document, title })
            return
          }
          if (!res.ok && res.message) {
            commit('MAKE_FILE_ERRORS', { err: [res.message], title })
            return
          }
          if (!res.ok) {
            throw 'something went wrong'
          }
          commit('PUT_FILE_TO_STATE', { file: info.file, name: title })
        })
        .catch((e) => commit('MAKE_FILE_ERRORS', { err: ['something went wrong'], title }))
        .finally(e => { commit('FILES_LOADING_HANDLER', { fileName: title, bool: false }) })
    },
    async fetchWarehouseProducts ({ commit }, { currentPage, itemsPerPage }) {
      commit('PRODUCTS_LOADING_HANDLER', { bool: true })

      await FetchAbstraction(true, 'GET', `/api/v1/warehouse-products/list?page=${currentPage}&per_page=${itemsPerPage}`, {}, {}, false )
        .then(response => {
          if (response.ok) {
            const options = {
              totalItems: response.res.total,
              itemsPerPage: response.res.per_page,
              currentPage: response.res.current_page,
            }
            const data = [...Object.values(response.res.data)];
            commit('SET_PRODUCTS', { products: data });
            commit('UPDATE_PAGINATION', options);
          }
        })
        .finally(() => {  commit('PRODUCTS_LOADING_HANDLER', { bool: false }) })
    },
    async shipProducts ({ commit, state, rootState }) {
      commit('SHIPPING_LOADING_HANDLER', { bool: true })
      commit('CLEAR_SHIPPING_ERRORS')

      const { checkoutFormFields, formFields, dateField } = state

      let fetchBody = {
        ...(checkoutFormFields.personalRef.name && { personal_ref: checkoutFormFields.personalRef.name }),
        delivery: {
          delivery_date: dateField.name,
          contact: rootState.userInfo.name,
          company_name: rootState.companyInfo.name,
          lvb: !!formFields.lvb.name,
          address: {
            ...(!formFields.lvb.name && {
              country: formFields.country.name === 1 ? 'Netherlands' : 'Belgium',
              street: formFields.street.name,
              house_number: formFields.houseNumber.name,
              place: formFields.city.name,
              postcode: formFields.zipCode.name
            }),
            ...(!formFields.lvb.name && formFields.addition.name && { addition: formFields.addition.name })
          },
        },
        products: []
      }
      if (formFields.lvb.name) {
        let lvbDeliveryOptions = JSON.parse(formFields.lvbOptions.name)
        fetchBody.delivery.lvb_location = lvbDeliveryOptions.id
        fetchBody.delivery.address = {
          place: lvbDeliveryOptions.city,
          country: lvbDeliveryOptions.country,
          house_number: lvbDeliveryOptions.house_number,
          postcode: lvbDeliveryOptions.postcode,
          street: lvbDeliveryOptions.street,
          ...(lvbDeliveryOptions.addition && { addition: lvbDeliveryOptions.addition })
        }
      }

      fetchBody.products = state.products
        .map(product => (product.available && product.checked && 
          { id: product.id, boxes_count: product.countOfBoxes, 
            product_subs: product.product_subs
              .map(
                (subProdItem) =>
                  subProdItem.checked &&
                  subProdItem.countOfSubProductBoxes && {
                    id: subProdItem.id,
                    warehouse_boxes: subProdItem.countOfSubProductBoxes
                  }
              )
              .filter((subProdItem) => !!subProdItem)
          }))
        .filter(product => !!product)

      await FetchAbstraction(true, 'POST', `/api/v1/shipment/create`, fetchBody, {}, false)
        .then(response => {
          if (!response.ok) {
            throw response.res.message || 'Something went wrong'
          } else {
            commit('SHIPPING_SUCCESSFULLY_FINISHED')
            commit('CLEAR_SHIPPING_ERRORS')
          }
        })
        .catch(error => {
          commit('SET_SHIPPING_ERRORS', { error })
        })
        .finally(() => commit('SHIPPING_LOADING_HANDLER', { bool: false }))
    },
    updatePersonalRef ({ commit }, { productId, personalReference }) {
      commit('SET_LOADING_PERSONAL_REF', { bool: true, productId })
      commit('SET_PERSONAL_REF_FEEDBACK', { error: '', success: false })

      const obj = {
        personal_ref: personalReference
      }
      return new Promise((resolve, reject) => { 
        FetchAbstraction(true, 'PATCH', `/api/v1/warehouse-products/${ productId }/update/personal_ref`, obj, {}, false)
        .then(response => {
          if (response.ok) {
            commit('SET_NEW_PERSONAL_REF', { productId, value: response.res })
            commit('SET_PERSONAL_REF_FEEDBACK', { error: '', success: true })
          } else {
            throw response.res || "You couldn't update personal ref"
          }
        })
        .catch(error => {
          commit('SET_PERSONAL_REF_FEEDBACK', { error, success: false })
        })
        .finally(() => {
          commit('SET_LOADING_PERSONAL_REF', { bool: false, productId })
          resolve()
        })
      })
    },
    async fetchShippedProducts ({ commit }, { currentPage, itemsPerPage }) {
      commit('SHIPPED_PRODUCTS_HANDLER', { bool: true })

      await FetchAbstraction(true, 'GET', `/api/v1/shipment/list?page=${currentPage}&per_page=${itemsPerPage}`, {}, {}, false)
        .then(response => {
          if (response.ok && response.status === 200) {
            const options = {
              totalItems: response.res.total,
              itemsPerPage: response.res.per_page,
              currentPage: response.res.current_page,
            }
            const data = [...Object.values(response.res.data)];
            commit('SET_SHIPPED_PRODUCTS', { products: data });
            commit('UPDATE_PAGINATION', options);
          }
        })
        .catch(e => console.log(e))
        .finally(() => { commit('SHIPPED_PRODUCTS_HANDLER', { bool: false }) })

    },
    setDeliveryAddressFromList({dispatch, commit, state}, { id, isToAutoFillData }) {
      return new Promise((resolve, reject) => {
        commit('CHANGE_LOADING_STATUS', {field: 'isLoadingTemplate', value: true})
        FetchAbstraction(true, 'GET', `/api/v1/delivery/layout/${id}`)
        .then((res) => {
          if (res.status === 200) {
            isToAutoFillData && commit('PUT_DELIVERY_INFO', { obj: res });
            !isToAutoFillData && commit('EDIT_TEMPLATE', res);
          }

          resolve(res)
        })
          .catch((e) => {
            console.log(e)
            reject(error)
          })
        .finally(() => commit('CHANGE_LOADING_STATUS', {field: 'isLoadingTemplate', value: false}))
      })
    },
    editDeliveryTemplateItem({ dispatch, commit, state }, id) {
      const data = {};
      Object.keys(DELIVERY_TEMPLATE_DATA).forEach(item => {
        data[item] = state.templateData[DELIVERY_TEMPLATE_DATA[item]].name;
      });
      return new Promise((resolve, reject) => {
        commit('CHANGE_LOADING_STATUS', {field: 'isChangingTemplateData', value: true});
        FetchAbstraction(true, 'POST', `/api/v1/delivery/layout/update/${id}`, data)
          .then((res) => {
            if (!res || res.message !== "Success") {
              return
            }
            commit('SET_SAVE_DELIVERY_TEMPLATE_ERRORS', { success: 'Sjabloon is succesvol gewijzigd', key: EDIT_ADDRESS_TEMPLATE_KEY })
            resolve()
        })
          .catch((error) => {
            console.log(error)
            commit('SET_SAVE_DELIVERY_TEMPLATE_ERRORS', { error, key: EDIT_ADDRESS_TEMPLATE_KEY })
            reject(error)
          })
          .finally(() => commit('CHANGE_LOADING_STATUS', {field: 'isChangingTemplateData', value: false}))
        })
    },
    async getDeliveryAddressList({ dispatch, commit, state }) {
      commit('MAKE_DELIVERY_LIST', { list: [] })
      await FetchAbstraction(true, 'GET', '/api/v1/delivery/layout/list', {}, {}, false)
        .then(({ res, ok }) => {
          if (ok) {
            commit('MAKE_DELIVERY_LIST', { list: res || [] })
          }
        })
        .catch((e) => console.log(e))
    },
    async saveDeliveryData({dispatch, commit, state}) {
      let deliveryDataFields = {
        city: state.formFields.city.name || '',
        country_index: state.formFields.country.name.toString() || '',
        street: state.formFields.street.name || '',
        house_number: state.formFields.houseNumber.name || '',
        post_code: state.formFields.zipCode.name || '',
        addition: state.formFields.addition.name || ''
      }
      return await FetchAbstraction(true, 'POST', '/api/v1/delivery/layout/create', deliveryDataFields)
        .then((res) => {
          if (!res.ok) {
            throw res.message || 'Something went wrong'
          }
        })
        .catch(error => {
          console.log('SaveDeliveryData' + error)
        })
    },
    async deleteAddressItem({dispatch, commit, state}, id) {
      await FetchAbstraction(true, 'POST', `/api/v1/delivery/layout/delete/${id}`)
        .then((res) => {
          if (!res.ok) {
            throw res.message || 'Er is iets fout gegaan'
          }
          commit('SET_SAVE_DELIVERY_TEMPLATE_ERRORS', { success: 'Sjabloon is succesvol verwijderd', key: DELETE_ADDRESS_TEMPLATE_KEY })
        })
        .catch(error => {
          commit('SET_SAVE_DELIVERY_TEMPLATE_ERRORS', { error, key: DELETE_ADDRESS_TEMPLATE_KEY })
        })
    },
    async addFileToMultiUploadDocument({ dispatch, commit, state }, { file }) {
      commit('CHANGE_FILE_UPLOADING_STATUS', { field: 'multiUploadFiles', value: true });
      const formData = new FormData();
      formData.append('document', file);
      return await FetchAbstraction(true, 'POST', '/api/v1/shipment/document/upload-shipment', formData)
        .then((res) => {
          if (!res.ok && res.document) {
            commit('ADD_MULTI_UPLOAD_ERROR_TEXT', { err: res.document });
            return
          };
          if (!res.ok && res.message) {
            commit('ADD_MULTI_UPLOAD_ERROR_TEXT', { err: [res.message] });
            return
          };
          commit('ADD_MULTI_LOAD_FILES', { file: file, id: res.uniqId });
        })
        .catch((e) => console.log(e))
        .finally(() => commit('CHANGE_FILE_UPLOADING_STATUS', { field: 'multiUploadFiles', value: false }))
    },

    async removeFileToMultiUploadDocument({ dispatch, commit, state }, id) {
      commit('CHANGE_FILE_REMOVING_STATUS', { field: 'multiUploadFiles', value: true })

      await FetchAbstraction(true, 'POST', `/api/v1/shipment/document/remove-shipment`, { uniqId: id })
        .then(res => {
          if (!res.ok) {
            commit('ADD_MULTI_UPLOAD_ERROR_TEXT', { err: res.document })
            return
          }
          commit('REMOVE_MULTI_LOAD_FILE', { id });
        })
        .catch((e) => console.log(e))
        .finally(() => commit('CHANGE_FILE_REMOVING_STATUS', { field: 'multiUploadFiles', value: false }))
    },
  },
  getters: {
    products (state) {
      return state.products
    },
    formFields (state) {
      return state.formFields
    },
    files (state) {
      return state.files
    },
    currentStep (state) {
      return state.currentStep
    },
    isFormFilling (state) {
      return state.isFormFilling
    },
    isProductsLoading (state) {
      return state.isProductsLoading
    },
    isShippingLoading (state) {
      return state.isShippingLoading
    },
    isSuccessfullyPassed (state) {
      return state.isSuccessfullyPassed
    },
    dateField (state) {
      return state.dateField
    },
    checkoutFormFields (state) {
      return state.checkoutFormFields
    },
    isSomePersonalRefsLoading (state) {
      return state.products.some(product => product.isPersonalRefLoading)
    },
    isCreateShipmentBtnDisabled (state, getters) {
      if (!state.products.length) return true
      return !state.products.some((product, index) =>
        product.available &&
        product.countOfBoxes &&
        Number.isInteger(product.countOfBoxes) &&
        !(product.countOfBoxes > product.maxBoxesCount) &&
        !(product.countOfBoxes < 1)
      )
    },
    isShippedLoading (state) {
      return state.isShippedProductsLoading
    },
    shippedProducts (state) {
      return state.shippedProducts
    },
    saveDeliveryLayout (state) {
      return state.saveDeliveryLayout
    },
    addressList (state) {
      return state.formFields.street.inputSettings.options
    },
    itemPerPage (state) {
      return state.pagination.itemsPerPage;
    },
    totalItems (state) {
      return state.pagination.totalItems;
    },
    currentPage (state) {
      return state.pagination.currentPage;
    },
    templateData (state) {
      return state.templateData;
    },
    isChangingTemplateData (state) {
      return state.isChangingTemplateData;
    },
    isLoadingTemplate (state) {
      return state.isLoadingTemplate;
    },
    mainTemplateField (state) {
      return state.formFields.street.name;
    },
    multiUploadFiles(state) {
      return state.multiUploadFiles;
    },
  }
}
