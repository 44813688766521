<template>
  <div class="modal">
    <h2>
      Wachtwoord vergeten?
    </h2>
    <p>
      Voer je e-mailadres in zodat we je een e-mail kunnen sturen om je wachtwoord te resetten.
    </p>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="validateStatus? 'error' : 'success'"
      >
        <template #help>
          <span
            style="color: red"
            v-show="dataState.forgotPasswordErrorText || validateStatus"
          >
              {{dataState.forgotPasswordErrorText || 'Voer alsjeblieft het e-mailadres in'}}
          </span>
        </template>
        <a-input
          allow-clear
          id="emailForgotPass"
          v-model="dataState.login.forgotPasswordModel.name"
          size="large"
          class="formWrapper__form--input"
          @focus="focusHandler('forgotPasswordModel', true, 'login')"
          @blur="focusHandler('forgotPasswordModel', false, 'login')"
        />
      </a-form-item>

      <label for="emailForgotPass"
             class="formWrapper__form--label"
             :class="dataState.login.forgotPasswordModel.isFocused ? 'focused' : ''"
      >
        E-mailadres
      </label>
    </div>
    <a-button-group size="large" class="form__verifyCodeNumber">
      <a-button type="primary"
                @click="forgotPassword"
                :loading="dataState.isModalForgotPasswordLoading"
      >
        Versturen
      </a-button>
      <a-button type="link"
                @click="cancelModal">
        cancel
      </a-button>
    </a-button-group>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordModal',
  props: {
    focusHandler: Function,
    cancelModal: Function,
    dataState: Object,
    forgotPassword: Function,
    validateStatus: Boolean
  }

}
</script>
