<template>
  <div class="loginWrapper">
    <div class="formWrapper">
      <a-card
        :bordered="false"
        :hoverable="true"
        class="card"
      >
      <div class="formWrapper__logo">
        <img :src="logo" alt="logo">
      </div>
      <div>
        <form class="formWrapper__form" @submit.prevent="submitHandler">
          <span class="formWrapper__form--subtitle">
            Voer jouw gegevens hieronder in
          </span>
          <div class="formWrapper__form--inputWrapper">
            <a-form-item
              :validate-status="($v.login.email.name.$dirty && $v.login.email.name.$invalid) ? 'error' : 'success'"
            >
              <template #help>
                <span v-show="$v.login.email.name.$dirty && $v.login.email.name.$invalid">
                  {{ `Voer alsjeblieft het e-mailadres in` }}
                </span>
              </template>
              <a-input
                allow-clear
                id="email"
                v-model="state.login.email.name"
                size="large"
                @change="state.login.email.name && !state.login.email.isFocused && focusHandler('email', true, 'login')"
                class="formWrapper__form--input"
                @focus="focusHandler('email', true, 'login')"
                @blur="focusHandler('email', false, 'login')"
                ref="input"
              />
            </a-form-item>
            <label for="email"
                   class="formWrapper__form--label"
                   :class="state.login.email.isFocused || isAutoFilled ? 'focused' : ''"
            >
              E-mailadres
            </label>
          </div>
          <div class="formWrapper__form--inputWrapper">
            <a-form-item
              :validate-status="($v.login.password.name.$dirty && $v.login.password.name.$invalid) ? 'error' : 'success'"
            >
              <template #help>
                <span v-show="$v.login.password.$dirty && $v.login.password.$invalid">
                  Voer alsjeblieft het wachtwoord in
                </span>
              </template>
              <a-input-password
                allow-clear
                id="pass"
                v-model="state.login.password.name"
                @change="state.login.password.name && !state.login.password.isFocused && focusHandler('password', true, 'login')"
                size="large"
                class="formWrapper__form--input"
                @focus="focusHandler('password', true, 'login')"
                @blur="focusHandler('password', false, 'login')"
              />
            </a-form-item>

            <label for="pass"
                   class="formWrapper__form--label"
                   :class="state.login.password.isFocused || isAutoFilled ? 'focused' : ''"
            >
              Wachtwoord
            </label>
          </div>
          <div class="formWrapper__form--forgotPswWrapper">
            <a class="formWrapper__form--forgotPsw"
               @click="changeForgotPasswordVisible(true)"
            >Wachtwoord vergeten?</a>
          </div>
          <div class="formWrapper__form--btnWrapper">
            <a-button
              type="primary"
              htmlType="submit"
              class="formWrapper__form--btn"
              :loading="loading"
              :disabled="loading"
            >
              Inloggen
              <a-icon type="login" />
            </a-button>
          </div>
          <div v-show="state.responseMessage">
            <a-alert :message="state.responseMessage"
                     type="error"
                     show-icon
            />
          </div>
          <p class="formWrapper__form--registerLink">
            Nog geen account?
            <router-link to="/register">Aanmelden</router-link>
          </p>
        </form>
      </div>
      </a-card>
      <RegistrationLoginFooter />
    </div>
<!--    <div class="illustWrapper">-->
<!--      <img :src="illustration"-->
<!--           class="illustWrapper__illust"-->
<!--           alt="illustration"-->
<!--      >-->
<!--    </div>-->
    <a-modal
      :visible="state.isModalForgotPasswordVisible"
      :confirm-loading="state.isModalForgotPasswordLoading"
      @cancel="cancelForgotPasswordModal"
      :footer="null"
      :closable="true"
    >
      <ForgotPasswordModal
        :focusHandler="focusHandler"
        :dataState="state"
        :validateStatus="$v.login.forgotPasswordModel.$dirty && $v.login.forgotPasswordModel.$invalid"
        :forgotPassword="forgotPass"
        :cancelModal="cancelForgotPasswordModal"
      />
    </a-modal>
  </div>
</template>

<script>

import videoAE from '@/assets/loginAE.mp4'
import logo from '@/assets/Logo-horizontaal.png'
import illustration from '@/assets/log-in-page.svg'
import {email, minLength, required, maxLength} from "vuelidate/lib/validators";
import focus from "@/mixins/focus";
import RegistrationLoginFooter from "@/components/RegistrationLoginFooter";
import {mapActions, mapMutations, mapState} from "vuex";
import ForgotPasswordModal from "@/components/ForgotPasswordModal";

export default {
  name: 'Login',
  components: {
    RegistrationLoginFooter,
    ForgotPasswordModal
  },
  mixins: [focus],
  data() {
    return {
      videoAE,
      logo,
      loading: false,
      illustration,
      isAutoFilled: false,
      idForTimeout: null,
    }
  },
  methods: {
    ...mapActions([
      'loginQuery',
      'forgotPassword'
    ]),
    ...mapMutations([
      'cancelForgotPasswordModal',
      'changeForgotPasswordVisible',
      'changeForgotPasswordErrorMessage'
    ]),

    submitHandler() {
      this.$v.$touch();
      if (this.$v.login.password.$error || this.$v.login.email.$error) {
        return;
      }
      this.loading = true
      this.loginQuery().then(res => {
        if (res.ok) {
          this.$router.push('/dashboard')
        }
      })
        .catch(e => console.log(e))
        .finally(e => this.loading = false)
    },
    forgotPass() {
      this.$v.login.forgotPasswordModel.$touch()
      if (this.$v.login.forgotPasswordModel.$error) {
        this.changeForgotPasswordErrorMessage({message: 'the fill shouldnt be empty and must be correct'})
      } else {
        this.forgotPassword()
      }
    },
    checkStyles() {
      this.idForTimeout = setTimeout(() => {
        const basedBackgroundColor = "rgba(0, 0, 0, 0)";
        let style = window.getComputedStyle(this.$refs.input.$el.children[0]);
        this.isAutoFilled = style && style.backgroundColor !== basedBackgroundColor;
      }, 700)
    },
  },
  computed: {
    ...mapState({
      login: state => state.login.login,
      state: state => state.login,
    })
  },
  mounted() {
    this.checkStyles();
  },
  beforeDestroyed() {
    clearTimeout(this.idForTimeout);
  },
  validations: {
    login: {
      email: {
        name: {
          required,
          email,
          maxLength: maxLength(250),
        }
      },
      password: {
        name: {
          required,
          minLength: minLength(10)
        }
      },
      forgotPasswordModel: {
        name: {
          required,
          email
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

.loginWrapper {
  width: 100%;
  display: flex;
  height: 100%;
  background-image: url("~@/assets/log-in-page.svg");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
}

.illustWrapper {
  height: 100%;
  width: 70%;
  margin-left: auto;
}

.illustWrapper__illust {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card {
  border-radius: 30px !important;
  min-width: 300px;
  width: 500px;
  -webkit-box-shadow: 0px 0px 25px -5px rgba(#FFA300, 1);
  -moz-box-shadow: 0px 0px 25px -5px rgba(#FFA300, 1);
  box-shadow: 0px 0px 25px -5px rgba(#FFA300, 1);
  padding: 15px;
}
.formWrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 45%;
  align-items: center;
  max-width: 1000px;
  margin-left: 70px;
}

.formWrapper__toLoginLink {
  text-align: end;
  margin-bottom: 40px;
}

.formWrapper__logo {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  img {
    width: 100%;
    height: 90px;
    object-fit: cover;
  }
}

.formWrapper__form {
  &--title {
    margin-bottom: 20px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &--subtitle {
    margin-bottom: 20px;
    display: flex;
  }

  &--inputWrapper {
    position: relative;
    margin-top: 15px;
  }

  &--forgotPsw {
    color: #FFA300;
    margin-bottom: 15px;

    &:hover {
      opacity: 0.86;
    }
  }

  &--forgotPswWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &--btn {
    width: 100%;
    margin-bottom: 15px;
  }

  &--btnWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &--registerLink {
    display: block;
    text-align: right;

    a {
      color: #FFA300;

      &:hover {
        opacity: 0.86;
      }
    }
  }
}

</style>
