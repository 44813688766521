import Vuex from 'vuex'
import Vue from 'vue'
import registerModule from './registerModule'
import loginModule from "./loginModule";
import warehouse from "./warehouse"
import CreateCompany from "./createCompany";
import dashboardMain from "@/store/dashboardMain";
import FetchAbstraction from "@/utils/FetchAbstraction";
import VueAnime from 'vue-animejs';

Vue.use(VueAnime)
Vue.use(Vuex)

const INITIAL_STATE = () => ({
  isLoggedIn: false,
  userInfo: {
    isLoading: false,
    loaded: false,
    companyId: null,
    createdAt: '',
    email: '',
    emailVerifiedAt: '',
    id: null,
    name: '',
    hasCompany: true,
    phone: {
      code: '',
      number: '',
    },
    phoneString: '',
    position: '',
    role: null,
    surname: '',
    updatedAt: '',
    dirId: '',
    photoUrl: '',
    isAdmin: null
  },
  companyInfo: {
    isLoading: false,
    address: {
      id: null,
      place: '',
      street: '',
      country: '',
      postCode: '',
      companyId: '',
      createdAt: '',
      updatedAt: ''
    },
    createdAt: '',
    eoriNumber: '',
    id: '',
    kboNumber: '',
    name: '',
    updatedAt: '',
    userId: '',
    vatNumber: ''
  }
});

export default new Vuex.Store({
  modules: {
    register: registerModule,
    login: loginModule,
    dashboardMain: dashboardMain,
    createCompany: CreateCompany,
    warehouse
  },
  state: INITIAL_STATE(),
  mutations: {
    changeLoggedInStatus(state, { bool, user }) {
      state.isLoggedIn = bool
      if (bool) state.userInfo.loaded = true
      if (bool) {
        state.userInfo.name = user.name
        state.userInfo.companyId = user.company_id
        state.userInfo.createdAt = user.created_at
        state.userInfo.email = user.email
        state.userInfo.emailVerifiedAt = user.email_verified_at
        state.userInfo.id = user.id
        state.userInfo.phone = { code: user.phone.code, number: user.phone.number }
        state.userInfo.phoneString = user.phone_string
        state.userInfo.position = user.position
        state.userInfo.hasCompany = user.has_company
        state.userInfo.role = user.role
        state.userInfo.surname = user.surname
        state.userInfo.updatedAt = user.updated_at
        state.userInfo.dirId = user.dir_id
        state.userInfo.photoUrl = user.photo_url
        state.userInfo.isAdmin = user.is_admin
      }
    },
    clearLocalStorage(state) {
      let storage = window.localStorage
      storage.clear()
    },
    saveCompanyInfo(state, res) {
      if (!res.id) return;
      state.companyInfo.address = {
        id: res.address.id,
        place: res.address.place,
        addition: res.address.addition,
        street: res.address.street,
        houseNumber: res.address.house_number,
        country: res.address.country,
        postCode: res.address.postcode,
        companyId: res.address.company_id,
        createdAt: res.address.created_at,
        updatedAt: res.address.updated_at
      }
      state.companyInfo.createdAt = res.created_at
      state.companyInfo.eoriNumber = res.eori_number
      state.companyInfo.id = res.id
      state.companyInfo.kboNumber = res.kbo_number
      state.companyInfo.name = res.name
      state.companyInfo.updatedAt = res.updated_at
      state.companyInfo.userId = res.user_id
      state.companyInfo.vatNumber = res.vat_number
    },
    changeCompanyInfoLoading(state, bool) {
      state.companyInfo.isLoading = bool
    },
    changeUserInfoLoading(state, bool) {
      state.userInfo.isLoading = bool
    },
    RESET_INPUT (state, { fieldData, validation }) {
      if (fieldData.name) fieldData.name = ''
      if (fieldData.isFocused) fieldData.isFocused = false

      validation.$reset()
    },
    FOCUS_HANDLER (state, { fieldData, toFocus, validation }) {
      if (!toFocus && !validation.$error) {
        validation.$touch()
      }
      if (toFocus || (fieldData.name !== null && fieldData.name !== '')) {
        if (!fieldData.isFocused) {
          fieldData.isFocused = true
        }
        return
      }
      fieldData.isFocused = false
    },
    CLEAR_STATE (state) {
      const clearedUserDataState = INITIAL_STATE();
      Object.keys(clearedUserDataState).forEach(key => state[key] = clearedUserDataState[key]);
    }
  },
  actions: {
    getUser({ commit, dispatch, state }, reloadAfterCreatingCompany) {
      if (state.userInfo.loaded && !reloadAfterCreatingCompany) return;
      commit('changeUserInfoLoading', true)
      return FetchAbstraction(true, 'GET', '/api/v1/auth/me')
        .then(response => {
          if (response.status === 401) {
            commit('changeLoggedInStatus', { bool: false })
            commit('clearLocalStorage')
          } else {
            commit('changeLoggedInStatus', { bool: true, user: response })
            localStorage.setItem('userId', response.id);
          }
          return response
        }).finally(() => commit('changeUserInfoLoading', false))
    },
    getCompanyInfo({ commit, dispatch, state }) {
      commit('changeCompanyInfoLoading', true)
      return FetchAbstraction(true, 'GET', '/api/v1/company')
        .then(response => {
          if (response.status === 200) {
            commit('saveCompanyInfo', response)
          }
          return response
        }).finally(() => commit('changeCompanyInfoLoading', false))
    },
    logout({ commit, dispatch }) {
      return FetchAbstraction(true, 'POST', '/api/v1/auth/logout')
        .then(response => {
          commit('changeLoggedInStatus', { bool: false })
          commit('clearLocalStorage')
          commit('CLEAR_STATE')
          return response
        })
    }
  },
  getters: {
    getFirstName(state) {
      return state.userInfo.name
    },
    getUserId(state) {
      return state.userInfo.id;
    },
  }
})

