import FetchAbstraction from "../utils/FetchAbstraction";

export default {
  state: {
    login: {
      email: {
        name: '',
        isFocused: '',
      },
      password: {
        name: '',
        isFocused: '',
      },
      forgotPasswordModel: {
        name: '',
        isFocused: '',
      }
    },
    forgotPasswordErrorText: '',
    responseMessage: '',
    isModalForgotPasswordLoading: false,
    isModalForgotPasswordVisible: false
  },
  mutations: {
    makeErrorMessage(state, msg) {
      state.responseMessage = msg
      setTimeout(() => state.responseMessage = '', 2000)
    },
    clearInputs(state) {
      state.login.email = {
        name: '',
        isFocused: ''
      }
      state.login.password = {
        name: '',
        isFocused: ''
      }
    },
    cancelForgotPasswordModal(state) {
      state.isModalForgotPasswordVisible = false
    },
    changeForgotPasswordVisible(state, bool) {
      state.isModalForgotPasswordVisible = bool
    },
    changeForgotPasswordLoading(state, bool) {
      state.isModalForgotPasswordLoading = bool
    },
    changeForgotPasswordErrorMessage(state, {message}) {
      state.forgotPasswordErrorText = message
      setTimeout(() => {state.forgotPasswordErrorText = ''}, 5000)
    }
  },
  actions: {
    async loginQuery({commit, dispatch, state}) {
      let user = {
        email: state.login.email.name,
        password: state.login.password.name
      }

      return await FetchAbstraction(false, 'POST', '/api/v1/auth/login', user)
        .then((res) => {
          if (!res.ok) {
            commit('makeErrorMessage', res.message)
            return res
          } else {
            let myStorage = window.localStorage
            myStorage.setItem('token', res.token)
            let expireDate = res.ttl * 60 * 1000 + (new Date).getTime()
            myStorage.setItem('expiresDate', expireDate + '')
            commit('clearInputs')
            return {
              ...res,
              expireDate
            }
          }
        })
        .catch(e => {
          throw e
        })
    },
    async forgotPassword({commit, dispatch, state}) {
      commit('changeForgotPasswordVisible', true)
      commit('changeForgotPasswordLoading', true)

      let user = {
        email: state.login.forgotPasswordModel.name
      }
      return await FetchAbstraction(false, 'POST', '/api/v1/auth/password/forgot', user)
        .then((res) => {
          if (!res.ok) {
            commit('changeForgotPasswordErrorMessage', {message: res.message})
          }
          commit('changeForgotPasswordVisible', false)
          return res
        })
        .catch(e => {
          commit('changeForgotPasswordErrorMessage', {message: e.message})
        })
        .finally(() => commit('changeForgotPasswordLoading', false))
    },
    async resetPassword({commit, dispatch, state}, {token, email, password, password_confirmation}) {
      if (token.length) {
        let data = {
          email,
          token,
          password,
          password_confirmation
        }
        return await FetchAbstraction(false, 'POST', '/api/v1/auth/password/reset', data)
          .then((res) => {
            console.log(res)
            return res
          })
          .catch(e => {
            throw e
          })
      }
    }
  },
  getters: {}
}
