<template>
  <div class="finishedStepWrapper">
    <div class="finishedStepWrapper__image">
      <img :src="image" alt="">
    </div>
    <h1 class="finishedStepWrapper__title">Bedankt voor je aanmmelding</h1>
    <p class="finishedStepWrapper__text">
      je registratie wordt momenteel geverifieerd en je krijgt antwoord vie de mail
    </p>
    <a-button
      type="primary"
      class="finishedStepWrapper__btn"
    >
      Ga naar dashboard
      <a-icon type="right"/>
    </a-button>
  </div>
</template>

<script>
  import image from '@/assets/Logo.png'
  import RegistrationLoginFooter from "./RegistrationLoginFooter";

  export default {
    name: 'RegistrationFinished',
    components: {
      RegistrationLoginFooter
    },
    data() {
      return {
        image
      }
    }
  }
</script>

<style lang="scss" scoped>
  .finishedStepWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 200px);
    &__image {
      height: auto;
      width: 100px;
      img {
        width: 100%;
      }
    }
    &__btn {
      margin: 20px 0;
    }
    &__title {

    }
    &__text {

    }
  }
</style>
