<template>
  <div style="height: 100%; position: relative">
    <router-view></router-view>
  </div>
</template>

<script>
  import Login from "@/containers/Login";
  import Register from "@/containers/Register";
  import FetchAbstraction from './utils/FetchAbstraction'
  import { makeNotification } from './utils/notificationHandler'

  export default {
    components: {
      Login,
      Register,
    },

    methods: {
      checkAppVersion() {
        const option = {
          type: 'warning',
          message: 'Je gebruikt een oude versie van de website.',
          description: 'Ververs je pagina om de nieuwe versie te gebruiken.',
        }

        return FetchAbstraction(false, 'GET', '/api/v1/version')
        .then(res => {
          if (res.app_version !== process.env.VUE_APP_VERSION) {
              makeNotification(option);     
            }
          })
        .catch(error => console.log(error));
      }
    },
    created() {
      if (window.Trengo && window.Trengo.key) return
      else {
        window.Trengo = window.Trengo || {};
        window.Trengo.key = 'K6eYjZAFeZyANg6';
        window.Trengo.extraOffsetX = '-5px';
        window.Trengo.extraOffsetY = '150px';
        (function(d, script, t) {
          script = d.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src = 'https://static.widget.trengo.eu/embed.js';
          d.getElementsByTagName('head')[0].appendChild(script);
        }(document));
      }

      this.checkAppVersion();

      setInterval(() => {
        this.checkAppVersion(); 
      }, 900000);
      window.onstorage = event => {
        const currentUserId = this.$store.getters.getUserId;
        const localStorageUserId = +event.storageArea.userId;
        if (currentUserId && localStorageUserId && (currentUserId !== localStorageUserId)) {
          document.location.reload();
        }
      };
    },
    data() {
      return {}
    },
  }
</script>

<style lang="scss">
  html, body {
    height: 100%;
    font-family: Stolzl-Medium, sans-serif !important;
  }

  h2 {
    font-family: Stolzl-Bold, sans-serif !important;
  }

  span {
    font-family: Stolzl-Thin, sans-serif !important;
  }

  .formWrapper__form {

    &--label {
      position: absolute;
      pointer-events: none;
      left: 10px;
      top: 13px;
      line-height: 1;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;

      &--phone {
        z-index: 2;
        left: 80px;
      }
    }

    &--label.focused {
      top: -8px;
      background: #fafaff;
      padding-left: 2px;
      padding-right: 2px;
      font-size: 14px;
      color: #FFA300;
      z-index: 2;
    }

    &--inputWrapper {
      position: relative;
      margin-top: 15px;

      .eori {
        margin-bottom: 0;
      }
    }
  }

  .form__verifyCodeNumber {
    display: flex;
    justify-content: space-between;
  }

  .form__inlineInputs {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 48%;
    }

  }

  .form__steps {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .ant-input {
    background: transparent !important;
    font-size: 16px !important;
  }

  i[aria-label="icon: search"] {
    display: none;
  }
  .avatar-uploader > .ant-upload {
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .ant-upload-picture-card-wrapper {
    display: block !important;
    width: auto !important;
  }
  .ant-select-selection {
    background: transparent !important;
  }
  .ant-collapse {
    background-color: #ffffff !important;
    border: none !important;
  }
  .ant-collapse-item {
    box-shadow: 1px 11px 27px -2px rgba(0, 0, 0, 0.2) !important;
    border-bottom: none !important;
  }
  .ant-collapse-content {
    border-top: none !important;
    background-color: #ffffff !important;
  }
  .ant-switch {
    background-color: #FFA300 !important;
  }
  .ant-collapse-header {
    user-select: none;
  }
  .documentPreview {
    max-width: 260px;
    max-height: 260px;
    object-fit: cover;
  }

  .form__avatar {
    display: flex;
    justify-content: center;
  }

  .avatarAlt {
    fill: #FFA300;
  }

  .avatarPreview {
    width: 125px;
    height: 125px;
    object-fit: cover;
  }

  .avatar__button {
    background: rgba(#F15A24, 1);
    padding: 5px 10px;
    width: 100%;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  ::-webkit-scrollbar-thumb {
    background: #FFA300;
    border-radius: 4px;
  }
  .collapsePanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(245, 244, 244);
    padding: 16px 0;

    &:last-child {
      padding-top: 16px;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .collapsePanel__product {
    &:not(:first-child) {
      margin-top: 20px;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid rgb(245, 244, 244);

      &:last-child {
        padding-top: 16px;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .totalForAllProducts {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    color: white;
    background-color: #FFA300;
    margin: 25px -16px -16px;

    span {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .antdCollapse {
    margin-bottom: 25px !important;
  }
  .btn_create_booking {
    width: 300px;
    height: 55px !important;
  }

  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .2s ease;
  }
  .component-fade-enter, .component-fade-leave-to {
    opacity: 0;
  }

  .slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 1000px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .TrengoWidgetGreeter__iframe{
    width: 347px !important;
  }

  .TrengoOnlineStatus__iframe{
    width: 200px !important;
  }
</style>
