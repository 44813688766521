<template>
  <div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.secondStep.companyName.name.$dirty && $v.secondStep.companyName.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.secondStep.companyName.name.$dirty && $v.secondStep.companyName.name.$invalid"
            >
              Onjuiste invoer
            </span>
          </template>
          <a-input
            allow-clear
            id="companyName"
            v-model="secondStep.companyName.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('companyName', true, 'secondStep')"
            @blur="focusHandler('companyName', false, 'secondStep')"
          />
        </a-form-item>

        <label for="companyName"
               class="formWrapper__form--label"
               :class="secondStep.companyName.isFocused ? 'focused' : ''"
        >
          Bedrijfsnaam
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.secondStep.cityName.name.$dirty && $v.secondStep.cityName.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.secondStep.cityName.name.$dirty && $v.secondStep.cityName.name.$invalid"
            >
             Onjuiste invoer
            </span>
          </template>
          <a-input
            allow-clear
            id="cityName"
            v-model="secondStep.cityName.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('cityName', true, 'secondStep')"
            @blur="focusHandler('cityName', false, 'secondStep')"
          />
        </a-form-item>

        <label for="cityName"
               class="formWrapper__form--label"
               :class="secondStep.cityName.isFocused ? 'focused' : ''"
        >
          Plaatsnaam
        </label>
      </div>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="($v.secondStep.country.name.$dirty && $v.secondStep.country.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="$v.secondStep.country.name.$dirty && $v.secondStep.country.name.$invalid"
            >
              Onjuiste invoer
            </span>
        </template>
        <a-input
          allow-clear
          id="country"
          v-model="secondStep.country.name"
          size="large"
          class="formWrapper__form--input"
          @focus="focusHandler('country', true, 'secondStep')"
          @blur="focusHandler('country', false, 'secondStep')"
        />
      </a-form-item>

      <label for="country"
             class="formWrapper__form--label"
             :class="secondStep.country.isFocused ? 'focused' : ''"
      >
        {{ secondStep.country.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="($v.secondStep.streetHouseName.name.$dirty && $v.secondStep.streetHouseName.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="$v.secondStep.streetHouseName.name.$dirty && $v.secondStep.streetHouseName.name.$invalid"
          >
            Onjuiste invoer
          </span>
        </template>
        <a-textarea
          allow-clear
          :auto-size="{ minRows: 3, maxRows: 5 }"
          id="streetHouseName"
          v-model="secondStep.streetHouseName.name"
          size="large"
          class="formWrapper__form--input"
          @focus="focusHandler('streetHouseName', true, 'secondStep')"
          @blur="focusHandler('streetHouseName', false, 'secondStep')"
        />
      </a-form-item>

      <label for="streetHouseName"
             class="formWrapper__form--label"
             :class="secondStep.streetHouseName.isFocused ? 'focused' : ''"
      >
        {{ secondStep.streetHouseName.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="($v.secondStep.houseNumber.name.$dirty && $v.secondStep.houseNumber.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="$v.secondStep.houseNumber.name.$dirty && $v.secondStep.houseNumber.name.$invalid"
            >
               Onjuiste invoer
            </span>
        </template>
        <a-input
          allow-clear
          id="houseNumber"
          v-model="secondStep.houseNumber.name"
          size="large"
          class="formWrapper__form--input"
          @focus="focusHandler('houseNumber', true, 'secondStep')"
          @blur="focusHandler('houseNumber', false, 'secondStep')"
        />
      </a-form-item>

      <label for="houseNumber"
             class="formWrapper__form--label"
             :class="secondStep.houseNumber.isFocused ? 'focused' : ''"
      >
        {{ secondStep.houseNumber.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="($v.secondStep.addition.name.$dirty && $v.secondStep.addition.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="$v.secondStep.addition.name.$dirty && $v.secondStep.addition.name.$invalid"
            >
               Onjuiste invoer
            </span>
        </template>
        <a-input
          allow-clear
          id="addition"
          v-model="secondStep.addition.name"
          size="large"
          class="formWrapper__form--input"
          @focus="focusHandler('addition', true, 'secondStep')"
          @blur="focusHandler('addition', false, 'secondStep')"
        />
      </a-form-item>

      <label for="addition"
             class="formWrapper__form--label"
             :class="secondStep.addition.isFocused ? 'focused' : ''"
      >
        {{ secondStep.addition.dictionary }}
      </label>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.secondStep.postCode.name.$dirty && $v.secondStep.postCode.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.secondStep.postCode.name.$dirty && $v.secondStep.postCode.name.$invalid"
            >
             Onjuiste invoer
            </span>
          </template>
          <a-input
            allow-clear
            id="postCode"
            v-model="secondStep.postCode.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('postCode', true, 'secondStep')"
            @blur="focusHandler('postCode', false, 'secondStep')"
          />
        </a-form-item>

        <label for="postCode"
               class="formWrapper__form--label"
               :class="secondStep.postCode.isFocused ? 'focused' : ''"
        >
          {{ secondStep.postCode.dictionary }}
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.secondStep.positionInCompany.name.$dirty && $v.secondStep.positionInCompany.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.secondStep.positionInCompany.name.$dirty && $v.secondStep.positionInCompany.name.$invalid"
            >
               Onjuiste invoer
            </span>
          </template>
          <a-input
            allow-clear
            id="positionInCompany"
            v-model="secondStep.positionInCompany.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('positionInCompany', true, 'secondStep')"
            @blur="focusHandler('positionInCompany', false, 'secondStep')"
          />
        </a-form-item>

        <label for="positionInCompany"
               class="formWrapper__form--label"
               :class="secondStep.positionInCompany.isFocused ? 'focused' : ''"
        >
          Functie in bedrijf
        </label>
      </div>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.secondStep.btwNumber.name.$dirty && $v.secondStep.btwNumber.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.secondStep.btwNumber.name.$dirty && $v.secondStep.btwNumber.name.$invalid"
            >
              Onjuiste invoer
            </span>
          </template>
          <a-input
            allow-clear
            id="btwNumber"
            v-model="secondStep.btwNumber.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('btwNumber', true, 'secondStep')"
            @blur="focusHandler('btwNumber', false, 'secondStep')"
          />
        </a-form-item>

        <label for="btwNumber"
               class="formWrapper__form--label"
               :class="secondStep.btwNumber.isFocused ? 'focused' : ''"
        >
          {{ secondStep.btwNumber.dictionary }}
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.secondStep.eoriNumber.name.$dirty && $v.secondStep.eoriNumber.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <h4 class="eori"
                v-show="$v.secondStep.eoriNumber.name.$dirty && !$v.secondStep.eoriNumber.name.isValid"
            >
              Deze EORI is niet geldig
            </h4>
            <span>Om erachter te komen wat je EORI-nummer is, bekijk dit <a href="https://ec.europa.eu/taxation_customs/dds2/eos/eori_validation.jsp" target="_blank">artikel</a></span>
          </template>
          <a-input-search
            allow-clear
            :loading="inputLoader"
            id="eoriNumber"
            :value="secondStep.eoriNumber.name"
            size="large"
            @change.native="eoriChangeHandler"
            class="formWrapper__form--input"
            @focus="focusHandler('eoriNumber', true, 'secondStep')"
            @blur="focusHandler('eoriNumber', false, 'secondStep')"
          />
        </a-form-item>
        <label for="eoriNumber"
               class="formWrapper__form--label"
               :class="secondStep.eoriNumber.isFocused ? 'focused' : ''"
        >
          {{ secondStep.eoriNumber.dictionary }}
        </label>
      </div>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.secondStep.kvkNumber.name.$dirty && $v.secondStep.kvkNumber.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.secondStep.kvkNumber.name.$dirty && $v.secondStep.kvkNumber.name.$invalid"
            >
              Onjuiste invoer
            </span>
          </template>
          <a-input
            allow-clear
            id="kvkNumber"
            v-model="secondStep.kvkNumber.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('kvkNumber', true, 'secondStep')"
            @blur="focusHandler('kvkNumber', false, 'secondStep')"
          />
        </a-form-item>

        <label for="kvkNumber"
               class="formWrapper__form--label"
               :class="secondStep.kvkNumber.isFocused ? 'focused' : ''"
        >
          KBO/{{secondStep.kvkNumber.dictionary}}
        </label>
      </div>
    </div>
    <a-list size="small" bordered
            v-show="isUniqueErrorsList.length"
            style="margin-top: 15px">
      <a-list-item v-for="(error, idx) in isUniqueErrorsList" :key="error + idx">
        <a-badge :status="'error'"
                 :text="error[0]"
        />
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import {email, minLength, required} from "vuelidate/lib/validators";
import {mapActions, mapMutations, mapState} from 'vuex';
import focus from "@/mixins/focus";
import FetchAbstraction from "../utils/FetchAbstraction";

export default {
  name: 'RegistrationSecondStep',
  mixins: [focus],
  props: {
    shouldCheckValidate: Boolean
  },
  watch: {
    shouldCheckValidate(val) {
      if (val === true) {
        this.checkValidate()
      }
    }
  },
  computed: {
    ...mapState({
      secondStep: state => state.register.secondStep,
    })
  },
  data() {
    return {
      loading: false,
      inputLoader: false,
      isUniqueErrorsList: []
    }
  },
  validations: {
    secondStep: {
      companyName: {
        name: {
          required,
          minLength: minLength(3)
        }
      },
      cityName: {
        name: {
          required,
          minLength: minLength(3)
        }
      },
      country: {
        name: {
          required,
          minLength: minLength(4)
        }
      },
      addition: {
        name: {
          minLength: minLength(1)
        }
      },
      houseNumber: {
        name: {
          required,
          minLength: minLength(1)
        }
      },
      streetHouseName: {
        name: {
          required,
          minLength: minLength(4)
        }
      },
      postCode: {
        name: {
          required,
          minLength: minLength(4)
        }
      },
      positionInCompany: {
        name: {
          required,
          minLength: minLength(2)
        }
      },
      btwNumber: {
        name: {
          required,
          minLength: minLength(4),
        }
      },
      eoriNumber: {
        name: {
          required,
          async isValid(value) {
            this.inputLoader = true
            let userEORI = {
              number: value,
            };
            return await FetchAbstraction(false, 'POST', '/api/v1/verification/eori', userEORI)
              .then((res) => {
                return res.ok
              })
              .catch((e) => console.log(e))
              .finally(() => this.inputLoader = false)
          }
        }
      },
      kvkNumber: {
        name: {
          required,
          minLength: minLength(4),
        }
      },
    },
  },
  methods: {
    async checkAreCodesUnique() {
      this.isUniqueErrorsList = []
      let userCode = {
        vat_number: this.secondStep.btwNumber.name,
        eori_number: this.secondStep.eoriNumber.name,
        kbo_number: this.secondStep.kvkNumber.name
      }

      await FetchAbstraction(false, 'POST', '/api/v1/validation/company', userCode)
        .then((res) => {
          if (res.ok) {
            this.secondStep.isStepFormValid = true;
            this.$emit('validationSuccess')
          } else {
            Object.keys(res).forEach(key => {
              if (key === 'eori_number' || key === 'kbo_number' || key === 'vat_number') {
                this.isUniqueErrorsList.push(res[key])
              }
            })
            this.$emit('validationFailed');
          }
        })
        .catch((e) => console.log(e))
    },
    eoriChangeHandler (e) {
      this.secondStep.eoriNumber.name = e.target.value
    },
    checkValidate() {
      this.$v.secondStep.$touch()
      if (this.$v.secondStep.$invalid) {
        this.$emit('validationFailed')
        return;
      }
      this.checkAreCodesUnique()
    }
  }
}
</script>
