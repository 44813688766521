export default function (h, error) {
  if (Object.prototype.toString.call(error.errors) === '[object Object]') {
    return Object.values(error.errors).map(er => {
      return er.map(e => (
        <p style={{ margin: 0 }}>{ e }</p>
      ))
    })
  } else {
    return error
  }
}
