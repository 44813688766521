import FetchAbstraction from "../utils/FetchAbstraction";
import router from "../router";

export default {
  state: () => ({
    // formStageCount: 3,
    currentFormStage: 1,
    isLoading: false,
    isFormCompleted: false,
    errorOnCreating: '',
    // formProgressTitle: 'Persoonlijke gegevens',
    // formProgressSubtitle: 'We hebben deze gegevens van je nodig om je goederen te kunnen transporteren en in te klaren bij de douane. Door je aan te melden ga je akkoord met de Algemene Voorwaarden en Privacy Verklaring.',
    firstStep: {
      name: {
        name: '',
        dictionary: 'Naam',
        isFocused: false
      },
      surname: {
        name: '',
        dictionary: 'Achternaam',
        isFocused: false
      },
      password: {
        name: '',
        dictionary: 'Wachtwoord',
        isFocused: false
      },
      repeatPassword: {
        name: '',
        dictionary: 'Herhaal wachtwoord',
        isFocused: false
      },
      email: {
        name: '',
        dictionary: 'E-mail',
        isFocused: false,
        loading: false,
        errorText: ''
      },
      positionInCompany: {
        name: '',
        dictionary: 'Positie binnen het bedrijf',
        isFocused: false
      },
      phone: {
        needVerification: true,
        name: '',
        dictionary: 'Telefoon',
        isFocused: false,
        code: '31',
        loading: false
      },
      sixCode: {
        name: '',
        isFocused: false,
        dictionary: 'Zes code',
        errorText: '',
        loading: false
      },
      isAgreeWithTerms: false,
      phoneHasVerifien: false,
      isModalConfirmPhoneVisible: false,
      isModalConfirmPhoneLoading: false,
      phoneValidationErrorText: '',
      isStepFormValid: false
    },
  }),
  mutations: {
    changeFormStage(state, direction, newTitle, newSubtitle) {
      if (direction === 'back') {
        state.currentFormStage--
      }
      if (direction === 'forward') {
        state.currentFormStage++
      }
      if (newTitle) {
        state.formProgressTitle = newTitle
      }
      if (newSubtitle) {
        state.formProgressSubtitle = newSubtitle
      }
    },

    changedPhone(state) {
      state.firstStep.phone.needVerification = true
      state.firstStep.phoneHasVerifien = false
    },
    changeModalVisible(state, show) {
      state.firstStep.isModalConfirmPhoneVisible = !!show;
    },
    checkSixCode(state, status) {
      state.firstStep.sixCode.errorText = ''
      if (status === 200) {
        state.firstStep.phoneHasVerifien = true
        state.firstStep.phone.needVerification = false
        state.firstStep.isModalConfirmPhoneVisible = false
      } else {
        state.firstStep.sixCode.errorText = 'error'
      }
    },
    agreeTermsHandler(state, bool) {
      state.firstStep.isAgreeWithTerms = bool
    },

    openNotificationWithIcon(state, obj) {
      state.errorOnCreating = 'something went wrong'
    },
    formCompleted(state) {
      state.isFormCompleted = true
    },
    makePhoneValidationErrorText(state, response) {
      state.firstStep.phoneValidationErrorText = response.message
      setTimeout(() => state.firstStep.phoneValidationErrorText = '', 5000)
    }
  },
  actions: {
    async sendForm({commit, state, dispatch}) {
      state.errorOnCreating = ''
      state.isLoading = true
      let user = {
        user: {
          position: state.firstStep.positionInCompany.name,
          name: state.firstStep.name.name,
          surname: state.firstStep.surname.name,
          email: state.firstStep.email.name,
          password: state.firstStep.password.name,
          phone: {
            code: state.firstStep.phone.code,
            number: state.firstStep.phone.name,
          }
        },
      };

      if (window.localStorage.getItem('id')) {
        user.affiliate_ref = window.localStorage.getItem('id')
      }

      return FetchAbstraction(false, 'POST', '/api/v1/auth/signup', user)
        .then((res) => {
          if (!res.ok) {
            commit('openNotificationWithIcon', {type: 'error', message: res})
          } else {
            let myStorage = window.localStorage
            let expireDate = res.ttl * 60 * 1000 + (new Date).getTime()
            myStorage.setItem('token', res.token)
            myStorage.setItem('expiresDate', expireDate + '')
            myStorage.removeItem('id')
            router.push('/dashboard')
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(e => { state.isLoading = false })
    },
    async firstStepValidation({commit, state, dispatch}, payload) {
      if (state.firstStep.phoneHasVerifien && !state.firstStep.phone.needVerification && state.errorOnCreating.length) {
        dispatch('sendForm')
        return;
      }
      state.firstStep.phone.loading = true
      let obj = {
        phone: {
          code: state.firstStep.phone.code,
          number: state.firstStep.phone.name
        }
      }
      await FetchAbstraction(false, 'POST', '/api/v1/verification/phone', obj)
        .then((res) => {
          if (!res.ok) {
            commit('makePhoneValidationErrorText', res)
            return
          }
          commit('changeModalVisible', true)
        })
        .catch((e) => console.log(e))
        .finally(() => state.firstStep.phone.loading = false)
    },
    async sendCodeAgain({commit, dispatch, state}, payload) {
      state.firstStep.phone.loading = true
      let obj = {
        phone: {
          code: state.firstStep.phone.code,
          number: state.firstStep.phone.name
        }
      }
      await FetchAbstraction(false, 'POST', '/api/v1/verification/phone', obj)
        .then((res) => {
          if (!res.ok) {
            commit('makePhoneValidationErrorText', res)
          }
        })
        .catch((e) => console.log(e))
        .finally(() => state.firstStep.phone.loading = false)

    },
    async modalVerifienPhone({commit, state, dispatch}, payload) {
      state.firstStep.sixCode.loading = true

      let user = {
        phone: {
          code: state.firstStep.phone.code,
          number: state.firstStep.phone.name
        },
        code: state.firstStep.sixCode.name
      };

      await FetchAbstraction(false, 'POST', '/api/v1/verification/phone/code', user)
        .then((res) => {
          commit('checkSixCode', res.status, res)
          if (res.status === 200) {
            dispatch('sendForm')
          }
        })
        .catch((e) => console.log(e))
        .finally(() => state.firstStep.sixCode.loading = false)
    },
    cancelModal({commit}, payload) {
      commit('changeModalVisible', false)
    },
    backButtonHandler({commit}, payload) {
      commit('changeFormStage', 'back')
    },
    changePhone({commit}, payload) {
      commit('changedPhone')
    },
  },
  getters: {}
}
